.wrapper {
  background: var(--white);
  position: relative;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--gray-200);
}

.addPrescriptionButton {
  color: var(--Green-900, #01533b);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  border: none;
  line-height: 20px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.title {
  color: var(--Gray-950, #26252c);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
