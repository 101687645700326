.btnWrapper {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background: transparent;
  /* border: none; */
  img {
    width: 18px;
    height: 18px;
    stroke: var(--green-900);
  }
}
.btnTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: var(--green-900);
  margin-bottom: 0 !important;
}

.disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
