.emptyState {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--gray-200);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 40px 0px;
  position: relative;
  width: 100%;
}

.emptyState .element {
  height: 160px;
  position: relative;
  width: 239.53px;
}

.emptyState .text {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 400px;
}

.emptyState .div {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.emptyState .textWrapper {
  align-self: stretch;
  color: #000000;
  font-weight: 700;
  font-size: 18px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.emptyState .onceThePatient {
  align-self: stretch;
  color: #000000;
  position: relative;
  text-align: center;
}

.emptyState .plusInstance {
  background-image: url(../../../../../assets/images/PlusIcon.svg) !important;
  height: 18px !important;
  position: relative !important;
  width: 18px !important;
}
.addPregnancyButton {
  background: var(--green-800);
  border: 1px solid var(--green-900);
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}
.addPregnancyButtonTitle {
  color: #fff;
}
