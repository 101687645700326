.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: 480px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
}

.button {
  border: 1px solid #01533b;
  color: #01533b;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 24px;
  border-radius: 12px;
  cursor: pointer;
  margin-top: 24px;
  width: 100%;
}
