/* start 01.06.23 */
.dashBcontbody h3 {
  font-weight: 600;
  line-height: 36px;
  color: #060030;
  margin-bottom: 8px;
}
.cleandarmainflexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0px 28px;
  position: relative;
}
/* Big calender start */
.bigclander {
  background: #fff;
  -webkit-box-shadow: 0px 2.309915781021118px 6.929748058319092px 0px #7885b226;
  box-shadow: 0px 2.309915781021118px 6.929748058319092px 0px #7885b226;
  border-radius: 20px;
  padding: 22px 0px 0px;
  position: relative;
  min-width: 65%;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid #0000001a;
}
.bigclander .rbc-allday-cell {
  display: none;
}
.bigclander .rbc-header {
  padding: 15px 0px;
  font-size: 16px;
  color: #060030;
  font-weight: 500;
  border-bottom: 0px;
  border-color: #0000001a;
  background: var(--white);
}
.bigclander .rbc-time-header.rbc-overflowing {
  background: #fafafd;
}
.bigclander .rbc-time-content {
  border-top: 1px solid #0000001a;
}
.bigclander .rbc-timeslot-group .rbc-time-slot {
  border: 0px;
}
.bigclander .rbc-label {
  padding: 0px 20px;
  font-size: 15px;
  font-weight: 400;
  color: #060030;
}
.bigclander .rbc-time-column .rbc-timeslot-group {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 165px;
  flex: 0 0 165px;
  max-height: 45px;
}
.bigclander .rbc-time-gutter > .rbc-timeslot-group {
  border-bottom: none;
  border-top: none;
}
.rbc-time-view {
  border-top: none !important;
}
.bigclander .rbc-label.rbc-time-header-gutter {
  min-width: 107px !important;
}
.bigclander .rbc-time-slot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 20px !important;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-top: 5px;
}
.bigclander .rbc-calendar {
  height: auto !important;
  position: relative;
  margin-top: -25px;
}
.bigclander .rbc-time-content {
  overflow: unset;
}
.bigclander .rbc-time-header {
  margin: 0px !important;
  padding-top: 20px;
}
.bigclander .rbc-header {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.bigclander .rbc-btn-group button:first-child {
  display: none;
}
.rbc-toolbar .rbc-toolbar-label {
  font-size: 20px !important;
  font-weight: 400 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.bigclander .rbc-toolbar span:nth-child(3) {
  display: none;
}
.bigclander .rbc-btn-group button:nth-child(3) {
  background: url("../../assets/images/arrow1.svg") no-repeat;
  background-size: 8px;
  background-position: center center;
  font-size: 0px;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  background-color: #e0e0ef;
  border: 1px solid #0000;
  border-radius: 16px !important;
  box-shadow: none;
  color: #8d8da3;
  height: 32px;
  width: 32px;
}
.bigclander .rbc-btn-group button:nth-child(2) {
  background: url("../../assets/images/arrow2.svg") no-repeat;
  background-size: 8px;
  background-position: center center;
  font-size: 0px;
  position: absolute;
  left: 0;
  top: 0;
  align-items: center;
  background-color: #e0e0ef;
  border: 1px solid #0000;
  border-radius: 16px !important;
  box-shadow: none;
  color: #8d8da3;
  height: 32px;
  width: 32px;
}
.bigclander .rbc-btn-group button {
  border: 0px;
  top: 7px;
}
.bigclander .rbc-toolbar {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 28px;
  font-size: 24px;
  color: #060030;
  font-weight: 600;
  letter-spacing: -3%;
  padding: 0px 30px;
  margin-left: 30px;
}
.bigclander .dropdown_view {
  position: absolute;
  right: 30px;
  top: 14px;
  z-index: 2;
}

.userProfileInitials {
  background-color: #a52a2a;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 20px;
  color: var(--white);
  margin-right: 10px;
}

.bigclander h4.title {
  position: relative;
  left: 350px;
  right: 0;
  width: 500px;
  font-size: 20px;
  font-weight: 600;
}

.bigclander h4.titleCenter {
  text-align: center;
  right: 0;
  width: 500px;
  left: 350px;
}

.bigclander h4 {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  overflow-wrap: break-word;
}
.bigclander .dropdown_view .formcontrol {
  width: 121px;
  height: 35px;
  border: 1px solid #d6d6e7;
  border-radius: 22px;
  padding: 0px 14px;
  color: #585861;
  font-size: 13px;
  font-weight: 500;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  position: relative;
  background-image: url("data:image/svg+xml,%0A%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.38407 8.77965L0.216529 2.612C0.0737664 2.46935 -0.00488281 2.27893 -0.00488281 2.07588C-0.00488281 1.87284 0.0737664 1.68241 0.216529 1.53976L0.670621 1.08556C0.966513 0.790003 1.44742 0.790003 1.74286 1.08556L6.92199 6.26468L12.1069 1.07981C12.2496 0.93716 12.4399 0.858398 12.6429 0.858398C12.846 0.858398 13.0363 0.93716 13.1792 1.07981L13.6332 1.53402C13.776 1.67678 13.8546 1.86709 13.8546 2.07014C13.8546 2.27318 13.776 2.46361 13.6332 2.60626L7.46003 8.77965C7.31681 8.92264 7.1256 9.00118 6.92233 9.00073C6.71827 9.00118 6.52717 8.92264 6.38407 8.77965Z' fill='black'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: right 14px center;
}

.bigclander .rbc-month-view .rbc-month-row {
  min-height: 92px;
}
.bigclander .rbc-month-view .rbc-header {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.bigclander .rbc-month-view .rbc-date-cell {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
  padding-right: 0px;
  text-align: center;
  padding-top: 20px;
}
/* Big calender end */

button.react-calendar__tile.react-calendar__month-view__days__day {
  color: #818181;
}

.accordion-body .react-calendar__tile--active {
  background: var(--green-800);
  color: #fff !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #5d35e9;
  color: white !important;
}

.react-calendar__month-view__days button {
  padding: 0;
  border-radius: 100%;
}

.react-calendar__month-view__days button abbr {
  height: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  font-size: 35px;
  color: #414141;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  font-size: 35px;
  color: #414141;
}

span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-size: 22px;
  font-weight: 700;
  color: #414141;
}

.react-calendar__month-view__weekdays__weekday {
  color: #818181;
  font-weight: 700;
  font-size: 16px;
}

button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: #cacaca;
  font-weight: 700;
  font-size: 16px;
}
/* end 01.06.23 */

/* start 06.06.23 */
.smallclander .react-calendar {
  width: 100%;
}
.smallclander .react-calendar__month-view__weekdays__weekday {
  color: #545a63;
  font-weight: normal;
  font-size: 14px;
  border: 0px;
}
.smallclander .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}
.smallclander .react-calendar__month-view__days button abbr {
  height: 56px;
}
.smallclander
  button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  color: #fff;
}
.smallclander
  button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: #cacaca;
  font-weight: 500;
  font-size: 14px;
}
.smallclander
  button.react-calendar__tile.react-calendar__month-view__days__day {
  color: #545a63;
  font-weight: 500;
  font-size: 14px;
}
.smallclander .react-calendar {
  background: white;
  border: 0px;
  -webkit-box-shadow: 0px 2.309915781021118px 6.929748058319092px 0px #7885b226;
  box-shadow: 0px 2.309915781021118px 6.929748058319092px 0px #7885b226;
  border-radius: 20px;
  margin-bottom: 34px;
  max-width: 400px;
}
.smallclander .react-calendar__navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ececfa;
}
.rbc-day-slot .rbc-events-container {
  margin-right: 0px;
}
button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  color: #fff;
}
.bigclander .rbc-event-label {
  display: none;
}
/* end 06.06.23 */

/* start 07.06.23 */
.smallclander {
  min-width: 34%;
}
/* end 07.06.23 */

/* start 24.06.23 */
.sidebarmenu {
  left: 0;
  position: absolute;
  background: var(--green-800);
  height: 60px;
  padding: 0px 0px;
  color: #fff;
  font-size: 0px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 0px 50px 50px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  transition: all ease 0.4s;
  z-index: -1;
  white-space: nowrap;
}
.sidebarbox .menulist:hover .sidebarmenu {
  left: 80px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 25px 0px 25px;
  font-size: 16px;
  -webkit-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.sidebarbox .menulist {
  cursor: pointer;
}
.sidebarbox .menulist:not(:last-child) {
  margin-bottom: 15px;
}
.sidebarbox .menulist.active {
  background: #fff;
}
/* end 24.06.23 */

/* Sidebar new style Start by AVN */
.sidebar {
  position: fixed;
  top: 0;
  left: 0px;
  height: 100%;
  width: 86px;
  background-color: var(--gray-50);
  padding: 28px 14px 28px 12px;
  z-index: 1;
}
.sidebar-links-wrapper {
  background: var(--green-800);
  border-radius: 24px;
  padding: 22px 8px;
}
.menulist {
  width: 42px;
  height: 42px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
}
.menulist img {
  width: 22px;
}
.menulist:not(:last-child) {
  margin-bottom: 18px;
}
.menulist.active {
  background: #fff;
}
.menulist.active::after {
  content: "";
  position: absolute;
  left: 0px;
  width: 3px;
  height: 28px;
  background: var(--green-800);
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
  border-radius: 9px 0px 0px 9px;
}
.sidebarmenu {
  left: 60px;
  position: absolute;
  background: var(--green-800);
  height: 50px;
  padding: 0px 0px;
  color: #fff;
  font-size: 0px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 0px 50px 50px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  transition: all ease 0.4s;
  z-index: -1;
  white-space: nowrap;
}
.menulist:hover .sidebarmenu {
  left: 60px;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  padding: 0px 25px 0px 25px;
  font-size: 16px;
  -webkit-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  transition: all ease 0.4s;
  font-weight: 500;
  line-height: 1.2;
}
.imgacctive {
  display: none;
}
.active .nonactive {
  display: none;
}
.active .imgacctive {
  display: block;
}
.nav__link.active .navicon {
  background: #fff;
}
/* Sidebar new style End by AVN */

/* Responsive Design */

@media only screen and (max-width: 1439px) {
  .menulist:not(:last-child) {
    margin-bottom: 8px;
  }
  .menulist {
    width: 32px;
    height: 32px;
  }
  .sidebar-links-wrapper {
    padding: 16px 8px;
  }
  .menulist img {
    width: 16px;
    height: 16px;
  }
  .menulist:hover .sidebarmenu {
    font-size: 12px;
    height: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .sidebar {
    display: none;
  }
  .menulist.active::after {
    display: none;
  }
  .menulist {
    width: auto;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-radius: 0px;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .menulist.active {
    background: transparent;
  }
  .menulist-icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    padding: 5px;
    background: transparent;
  }
  .menulist.active .menulist-icon-wrap {
    background: #fff;
  }
}
