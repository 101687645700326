.pregnancyPostNatalView {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--gray-200);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
  width: 100%;
}

.pregnancyPostNatalViewConsultationMode {
  padding-bottom: 0;
}

.pregnancyPostNatalViewTitleWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.pregnancyPostNatalViewTitle {
  color: var(--gray-950);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.pregnancyPostNatalStartConsultationButton {
  background: var(--green-800);
  border: 1px solid var(--green-900);
  border-radius: 12px;
  padding: 12px 24px;
  width: 180px;
  height: 48px;
  color: var(--white);
}

.pregnancyPostNatalConsultationWrapper {
  display: grid;
  grid-template-columns: 64px 1fr 18px;
  gap: 24px;
  width: 100%;
  padding: 12px 0;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--gray-200);
}

.pregnancyPostNatalConsultationWrapper:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.pregnancyPostNatalStartConsultationsWarpper {
  width: 100%;
  padding-right: 12px;
  padding-top: 26px;
}
