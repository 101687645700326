.pregnancySidebar {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--gray-200);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
  width: 100%;
}

.pregnancySidebarItem {
  width: 100%;
}

.pregnancySidebarItemHeaderWrapper {
  display: flex;
  border-bottom: 1px solid #d9d8df;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.pregnancySidebarItemHeaderInnerWrapper {
  display: flex;
  padding: 12px 16px;
  width: 100%;
  gap: 10px;
  align-items: center;
}

.pregnancySidebarItemHeaderInnerWrapper h3 {
  color: var(--gray-950);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.pregnancySidebarItemHeaderInnerWrapper p {
  color: var(--gray-950);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
}

.pregnancySidebarItemHeaderInnerWrapper button {
  background-color: transparent;
  width: 40px;
  height: 34px;
  border: none;
}

.pregnancySidebarItem.pregnancySidebarItemExpanded
  .pregnancySidebarItemHeaderInnerWrapper
  button {
  transform: rotate(180deg);
}

.pregnancySidebarItemHeaderInnerWrapper img {
  width: 16px;
  height: 16px;
}

.pregnancySidebarItem.pregnancySidebarItemNotExpanded:last-child
  .pregnancySidebarItemHeaderWrapper {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.pregnancySidebarItemHeaderTitleWrapper {
  flex-grow: 1;
}

.pregnancySidebarItemButtonWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid #d9d8df;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.pregnancySidebarItem.pregnancySidebarItemExpanded:last-child
  .pregnancySidebarItemButtonWrapper {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.pregnancySidebarItemButton {
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: white;
  border: none;
  color: var(--gray-950);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding: 12px 16px;
}

.pregnancySidebarItemButton:hover {
  background-color: #e7efeb;
  border-radius: 12px;
}

.pregnancySidebarItemButton.pregnancySidebarItemButtonActive {
  background-color: #e7efeb;
  border-radius: 12px;
}

.addPregnancyButton {
  background-color: white;
  color: var(--green-800);
  border: 1px solid var(--green-800);
  font-size: 18px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 100%;
  height: 48px;
  margin-bottom: 16px;
}
