.consultationDateBox {
  display: flex;
  height: 66px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--green-950);
  background: var(--green-50);
}

.consultationDateBox span:first-child {
  color: var(--green-950);
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
}

.consultationDateBox span:nth-child(2) {
  color: var(--green-950);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}
