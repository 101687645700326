.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  position: relative;
  background: #fff;
  padding: 24px;
  border-radius: 18px;
  width: 1000px;
  max-width: 100%;
  z-index: 1;
}
.modaHeaderBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  /* background: #f0f0f9;
  border-bottom: 1.17px solid rgb(217, 215, 215); */
  z-index: -1;
}
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modalBody {
  max-height: 70vh;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
.footerButton {
  border: 1px solid var(--green-900);
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}
.footerButtonSave {
  background: var(--green-800);
  border: 1px solid var(--green-900);
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}
.footerButtonSaveTitle {
  color: #fff;
}
.footerButtonSaveTitle {
  color: #fff;
}
.saveButton:hover {
  background-color: #218838;
}
