.container {
  display: flex;
  flex-direction: column;
  height: 100dvh;
}

.contentContainer {
  position: relative;
  padding: 20px 20px 40px 20px;
  height: 100%;
}

.rightContainer {
  display: none;
}

.rightBgImage {
  object-fit: cover;
  filter: brightness(0.95);
  height: 100dvh;
  width: 100%;
}

.ellipse1,
.ellipse2,
.ellipse3,
.dots {
  display: none;
}

.logo {
  margin-bottom: 20px;
}

.versionData {
  font-size: 12px;
  color: var(--black);
  margin-left: 20px;
  margin-bottom: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (min-width: 600px) {
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .logo {
    position: absolute;
    margin-bottom: 0;
  }

  .ellipse1 {
    position: absolute;
    top: 16px;
    right: 0;
  }

  .ellipse3,
  .dots {
    position: absolute;
    bottom: 0;
    left: 20%;
  }

  .ellipse1,
  .ellipse3,
  .dots {
    display: block;
    pointer-events: none;
  }

  .ellipse2 {
    display: none;
  }
}

@media screen and (min-width: 1080px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 400px;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .rightContainer {
    display: block;
    overflow: hidden;
    width: 100%;
    overflow: hidden;
  }

  .ellipse2 {
    display: block;
    position: absolute;
    top: 10%;
    left: 0;
  }
}
