.listContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.listItemContainer {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  gap: 35px;
  border-bottom: 1px solid var(--gray-200);
  align-items: center;
}
.listItemContainer:last-child {
  border-bottom: none;
}
.listItemTitle {
  font-weight: 700;
  font-size: 14px;
  color: var(--gray-950);
  display: block;
  width: 200px;
}
.listItemDescr {
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: var(--gray-950);
}
.editButton {
  border: none;
}
