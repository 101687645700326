.consultationBox {
  background-color: #e7efeb;
  border: 1px solid #002f22;
  border-radius: 12px;
  padding: 24px 16px;
  width: 100%;
}
.rightBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2px 0;
}

.rightBox > p {
  font-size: 16px;
  font-weight: 400;
  color: var(--gray-500);
}

.rightBox > p > span {
  font-weight: 700;
}

.consultationTitle {
  font-size: 28px;
  font-weight: 700;
  color: var(--green-900);
}

.priceInputWrapper input,
.priceInputWrapper select,
.priceInputWrapper textarea {
  height: 48px;
  border-radius: 12px !important;
  color: var(--gray-500, #717186);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px;
  width: 100%;
  max-width: 260px;
}

.button {
  border-radius: 12px;
  background-color: var(--green-900);
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  box-sizing: border-box;
  text-align: left;
  color: #fdfdfd;
  cursor: pointer;
  border: none;
  width: 100%;
}

.button:disabled {
  background-color: var(--bs-secondary-bg);
  border: 1px solid var(--gray-400);
  color: #717186;
  cursor: not-allowed;
}

.button:disabled p {
  color: #717186;
}

.paymentConfirmButton {
  margin-top: 16px;
  width: 100%;
  max-width: 300px;
  margin-left: auto;
}

.button p {
  color: #fdfdfd;
}

.consultationBoxTop {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.consultationBoxTop > *:nth-child(1) {
  padding-right: 26px;
  border-right: 1px solid #b7b8c2;
  border-radius: none;
}

.consultationBoxTop > *:nth-child(2) {
  padding-left: 26px;
}

.consultationBoxTopItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
}

.consultationBoxTopItem > p:nth-child(1) {
  font-size: 16px;
}

.consultationBoxTopItem > p:nth-child(2) {
  font-size: 20px;
  font-weight: 700;
}

.consultationBoxWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  flex-grow: 1;
}

.paymentSection {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--gray-200);
  width: auto;
  flex-grow: 1;
}

.paymentSection > h2 {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}

.paymentSectionNoPayment {
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 500px;
  margin: 24px auto 0;
}
.paymentSectionNoPayment h3 {
  font-size: 18px;
  font-weight: 700;
}

.paymentSectionNoPayment p {
  font-size: 16px;
  max-width: 320px;
  margin-top: 8px;
}

.serviceSelection {
  padding: 20px;
  border-bottom: 1px solid #e5e7eb;
}

.addServiceButton {
  color: #1f725f;
  background: none;
  border: none;
  padding: 10px 0;
  cursor: pointer;
  font-weight: 500;
  width: 100%;
  text-align: center;
}

.addServiceButton:disabled {
  color: #717186;
  cursor: not-allowed;
}

.paymentDetails {
  padding: 20px;
}

.paymentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.paymentHeader h2 {
  font-size: 18px;
  font-weight: 600;
}

.paymentItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 8px;
}

.serviceInfo {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.iconWrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid var(--Green-950, #002f22);
  background: var(--Green-50, #e7efeb);
}

.price {
  font-weight: 500;
}

.addServiceWrapper {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #d9d8df;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.priceInputWrapper {
  position: relative;
}

.paymentItems {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.priceControls {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  max-width: 300px;
}

.deleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: #717186;
  transition: color 0.2s ease;
}

.deleteButton:hover {
  color: #dc3545;
}

.priceInputWrapper {
  width: 100%;
}
