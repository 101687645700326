.cardWrapper {
  width: 100%;
  position: relative;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--gray-200);
  background: #fff;
}
.topWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.titleWrapper {
  display: flex;
  align-items: center;
  gap: 11px;
}
.title {
  font-weight: 600;
  font-size: 20px;
  color: #060030;
}
.button {
  background: none;
  border: none;
  cursor: pointer;
}
.icon {
  width: 16px;
  height: 16px;
}
