.rbc-time-header-content {
}

.rbc-header {
  border-color: var(--gray-400) #0000001a;
  border-style: solid;
  border-width: 1px 0 0;
  height: 100%;
  padding: 15px 5px;
  font-size: 16px;
  background: var(--white) !important;
  max-height: 65px;
}

.bigclander .rbc-time-column .rbc-timeslot-group .rbc-timeslot-group {
  border-right: 1px solid #ececfa;
  border-bottom: none !important;
  border-top: none !important;
  color: blue;
}

.rbc-time-gutter .rbc-time-column {
  border-bottom: none !important;
}

.event {
  overflow: visible !important;
  border: 1px solid #dbdbe9 !important;
  background: transparent;
}

.eventTitle {
  font-size: 12px;
  color: #000;
  background: transparent !important;
}

.timeDuration15 {
  background: #e7eefa;
  border-left: 3px solid #458aff;
  color: black;
  border-left: #458aff 3px solid !important;
}

.timeDuration30 {
  background: #fffaea;
  border-left: 3px solid #ffc200 !important;
  color: black;
}

.timeDuration45 {
  background: #fff1f7;
  border-left: 3px solid #ff006d !important;
  color: black;
}

.timeDuration60 {
  background: #e5fff6;
  border-left: 3px solid var(--green-800) !important;
  color: black;
}
