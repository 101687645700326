.openModalButton {
  background-color: white;
  color: var(--green-800);
  border: 1px solid var(--green-800);
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  height: 48px;
}
