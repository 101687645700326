.patientDetailCard {
  position: relative;
  width: 680px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--gray-200);
  background: #fff;
  text-wrap: none;
  flex-grow: 1;
}
.leftHalfRoundImg {
  position: absolute;
  top: 85px;
  left: -23px;
}
.bottomHalfRoundImg {
  position: absolute;
  bottom: 0;
  left: 60px;
}
.rightHalfRoundImg {
  position: absolute;
  bottom: 0;
  right: 0;
}
.patientName {
  display: flex;
  gap: 5px;
  align-items: center;
}
.patientAge {
  font-size: 16px;
  font-weight: 400;
  color: #858585;
}
.patientHeaderWrap {
  position: relative;
  padding-bottom: 30px;
}

.additionalBtnsWarpper {
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr; */
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.additionalBtnsWarpper > button {
  width: auto !important;
  flex-grow: 1;
}
.editBtn {
  border: none;
}
.actionBtnsWrapper {
  position: absolute;
  right: 0;
  top: 90px;
  gap: 10px;
}

.patientOverviewTitle {
  margin-top: 18px;
  width: calc(100% + 120px);
}

.actionBtn {
  border: 1px solid var(--green-900);
  border-radius: 12px;
  padding: 12px 24px;
  /* width: 237px; */
  height: 48px;
}

.dFlex {
  display: flex;
}

.flexSmNoWrap {
  flex-wrap: nowrap;
}

.flexWrap {
  flex-wrap: wrap;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyContentSmStart {
  justify-content: flex-start;
}

.patientProfileImgWrap {
  margin-right: 30px;
}

.userProfileImage {
  background-color: #f0f0f0;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  font-size: 1.5rem;
  color: #333;
}
.patientNameSurname {
  font-size: 23px;
  font-weight: 600;
}

.patientHeaderContentWrap {
  align-self: flex-start;
  flex-grow: 1;
  max-width: 500px;
}

.patientHeaderContentWrap p {
  margin-bottom: 4px;
}

.patientBottomWrap {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}
.patientLeftDescription {
  margin-right: 50px;
}

.patientLeftDescription,
.patientRightDescription {
  flex: 1;
  width: 45%;
}

.patientBlockWrap {
  margin-bottom: 1rem;
}

.textManatee {
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: var(--gray-600);
  margin-bottom: 5.5px;
}

.fw500 {
  font-weight: 500;
}

.h6 {
  font-size: 1rem;
  margin: 0;
}

.textStratos {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--gray-950);
  text-wrap: normal;
}

.textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flexShrink0 {
  flex-shrink: 0;
}

.btnWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.divider {
  width: 1.75px;
  height: 134px;
  background-color: #ececfa;
  margin-right: 20px;
  margin-left: 10px;
  align-self: center;
}
.customBtn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.customBtn:hover {
  background-color: #0056b3;
}
@media screen and (max-width: 1440px) {
  .patientDetailCard {
    width: 100%;
  }
  .userProfileImage {
    width: 80px;
    height: 80px;
  }
  .actionBtnsWrapper {
    top: 100px;
    right: 50%;
    transform: translateX(50%);
  }
}

.moreButtonWrapper {
  position: relative;
}

.moreButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 16px 20px;
}

.dropdownMenu {
  position: absolute;
  min-width: 210px;
  top: 40px;
  right: 0;
  background-color: white;
  border: 1px solid var(--gray-200);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  z-index: 1000;
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  white-space: nowrap;
  font-weight: 600;
  font-size: 16px;
  color: #25204a;
  background: transparent;
  border: 1px solid transparent;
}

.dropdownItem img {
  margin-right: 8px;
}

.dropdownItem:hover {
  background: var(--gray-50);
}

.dropdownItem:hover span {
  color: var(--gray-950);
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
}

.emptyStateTitle {
  font-size: 16px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 8px;
}

.emptyStateText {
  font-size: 14px;
  color: #717186;
  max-width: 300px;
}

.loadingSpinner {
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
  color: #717186;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
