.wrapper {
  background: #fff;
  border-radius: 12px;
}

.tableWarpper {
  width: 100%;
  position: relative;
  background-color: #ffffff;
  background: #fff;
}

.sortByButtonMenuOptionActive {
  background-color: var(--gray-100) !important;
}
.consultationPreviewWrapper {
  padding: 0 16px;
}

.table {
  border-radius: 12px;
  border: 1px solid var(--gray-200);
}

.searchWrapper {
  border-right: 1px solid var(--gray-200);
  max-width: 330px;
}

@media (max-width: 991px) {
  .searchWrapper img {
    display: none;
  }
}

.tableTopBarWrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-200);
}

.sortByButton {
  color: var(--green-900);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: none;
  background-color: transparent;
  height: 52px;
  border-left: 1px solid var(--gray-200);
}

.sortByButtonMenuOption p {
  display: flex;
  gap: 8px;
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sortByButtonMenuOptionActive {
  background-color: var(--gray-100) !important;
}
.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
  text-align: center;
  background: #f8f9fa;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.emptyStateTitle {
  font-size: 16px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 8px;
}

.emptyStateText {
  font-size: 14px;
  color: #717186;
  max-width: 300px;
}

.loadingSpinner {
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
  color: #717186;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.searchWrapper input:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
  opacity: 0.7;
}

.sortByButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.noteText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.container {
  background: var(--white);
  position: relative;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--gray-200);
}

.title {
  color: var(--Gray-950, #26252c);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.consultationDoctorSection {
  padding: 12px 0;
  margin-bottom: 12px;
}

.consultationDoctorSection h3 {
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.consultationDoctorSection p {
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.sortByButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.sortByButton {
  color: var(--green-900);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: none;
  background-color: transparent;
  height: 52px;
  border-left: 1px solid var(--gray-200);
}

.sortByButtonMenuOption p {
  display: flex;
  gap: 8px;
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sortByButtonMenuOptionActive {
  background-color: var(--gray-100) !important;
}

.menuButton {
  border: none;
  background-color: transparent;
  padding: 0;
  width: 26px;
  height: 26px;
  border-radius: 2px;
}

.menuButton:hover,
.menuButton:active {
  border: 1px solid var(--gray-300);
}

.menuItemWrapper {
  padding: 0;
}
.menuItem {
  padding: 0 !important;
}

.menuItem a,
.menuItem button,
.menuItem p {
  font-family: Nunito;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: none;
  background-color: transparent;
  width: 100%;
  padding: 12px 20px;
}
