.longTermWrapper {
  background: var(--white);
  position: relative;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--gray-200);
}
.topWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  /* padding: 24px 24px 0px 24px; */
  align-items: center;
  margin-bottom: 24px;
  min-height: 48px;
}
.headerTableBackground {
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  height: 41px;

  display: flex;
  padding: 12px;

  align-items: flex-start;
  align-self: stretch;

  border-radius: 12px 12px 0px 0px;
  border-bottom: 1px solid var(--gray-200, #d9d8df);
  background: var(--gray-50, #eeeef0);
}
.title {
  font-weight: 600;
  font-size: 20px;
  color: #060030;
}
.intoleranceTitle {
  padding: 25px 0;
  border-top: 1.17px solid #ececfa;
  border-bottom: 1.17px solid #ececfa;
  margin-bottom: 25px;
}
.columnNames {
  position: absolute;
  top: 74px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 30px 10px 73px;
  background: #f4f4fc;
}
.columnName {
  font-weight: 600;
  color: #626276;
  font-size: 16px;
}
.allergiesData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
}

.Divider {
  margin-bottom: 25px;
}
form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-bottom: 15px;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
/* label {
  width: 270px;
  display: block;
} */
.inputWrapperCheckbox {
  display: flex;
  gap: 10px;
  align-items: center;
}
.checkbox {
  display: flex;
  justify-self: flex-start;
  border: var(--gray-400) 1px solid;
  border-radius: 18px;
}
.checkboxLabel {
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: var(--gray-950);
  margin-bottom: 0;
}
.saveButton {
  background-color: var(--green-800);
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}
.addBtn {
  border: none;
}

.emptyState2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
  text-align: center;
  background: #f8f9fa;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.emptyStateTitle2 {
  font-size: 16px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 8px;
}

.emptyStateText2 {
  font-size: 14px;
  color: #717186;
  max-width: 300px;
}
