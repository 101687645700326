.consultationForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 0;
}

.consultationForm input,
.consultationForm select,
.consultationForm textarea {
  height: 48px;
  border-radius: 12px !important;
  color: var(--gray-500, #717186);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px;
}

.saveButton:hover {
  text-decoration: underline;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  position: relative;
}

.buttonRow button {
  flex-grow: 1;
}

.buttonRow .submitButton {
  background-color: var(--green-800);
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}

.buttonRow .cancelButton {
  background-color: white;
  color: var(--green-800);
  border: 1px solid var(--green-800);
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  height: 44px;
}

.formLabel {
  color: var(--Gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}

.formTooltip {
  position: relative;
  margin-top: -4px;
}

.checkboxLabel {
  color: var(--gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  align-items: center;
  display: flex;
  gap: 8px;
}

.sectionLegend {
  color: var(--Gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2px;
}

.inputGroupGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-top: 16px;
}

.bottomSectionInputGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}

.servicesGroup {
  padding: 24px 0;
}

.consultationForm input {
  height: 48px;
}

.datePickerWrapper > div > div {
  height: 48px;
  border: 1px solid var(--gray-400);
  border-radius: 12px;
  font-family: "nunito";
  font-size: 14px;
}

.datePickerWrapper fieldset {
  border: none;
}
