.longTermWrapper {
  background: var(--white);
  position: relative;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--gray-200);
}

.editConsultForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 0;
}

.editConsultForm input,
.editConsultForm select,
.editConsultForm textarea {
  height: 48px;
  border-radius: 12px !important;
  color: var(--gray-500, #717186);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px;
}

.actionBtn {
  border: 1px solid var(--green-900);
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}

.saveButton:hover {
  text-decoration: underline;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  position: relative;
  padding-top: 24px;
}

.buttonRow::after {
  content: "";
  position: absolute;
  top: 0;
  height: 1px;
  background-color: var(--gray-400);
  left: -24px;
  right: -24px;
}

.buttonRow .editConsultButton {
  background-color: var(--green-800);
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}

.buttonRow .cancelButton {
  background-color: white;
  color: var(--green-800);
  border: 1px solid var(--green-800);
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  height: 44px;
}

.formLabel {
  color: var(--Gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}

.editConsultFormTooltip {
  position: relative;
  margin-top: -4px;
}

.pregnancyFormCheckboxLabel {
  color: var(--gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  align-items: center;
  display: flex;
  gap: 8px;
}

.checkboxSectionLegend {
  color: var(--Gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2px;
}

.inputGroupGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.bottomSectionInputGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}

.medsCheckboxGroup {
  padding: 24px 0;
}

.editConsultForm input {
  height: 48px;
}

.editConsultDatePickerWrapper > div > div {
  height: 48px;
  border: 1px solid var(--gray-400);
  border-radius: 12px;
  font-family: "nunito";
  font-size: 14px;
}

.editConsultDatePickerWrapper fieldset {
  border: none;
}

.topWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 24px 16px 24px;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 24px;
  width: calc(100% + 48px);
  margin-left: -24px;
  border-bottom: 1px solid var(--gray-200);
}
.headerTableBackground {
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  height: 41px;

  display: flex;
  padding: 12px;

  align-items: flex-start;
  align-self: stretch;

  border-radius: 12px 12px 0px 0px;
  border-bottom: 1px solid var(--gray-200, #d9d8df);
  background: var(--gray-50, #eeeef0);
}
.title {
  font-weight: 600;
  font-size: 20px;
  color: #060030;
}
.intoleranceTitle {
  padding: 25px 0;
  border-top: 1.17px solid #ececfa;
  border-bottom: 1.17px solid #ececfa;
  margin-bottom: 25px;
}
.columnNames {
  position: absolute;
  top: 74px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 30px 10px 73px;
  background: #f4f4fc;
}
.columnName {
  font-weight: 600;
  color: #626276;
  font-size: 16px;
}
.allergiesData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
}

.Divider {
  margin-bottom: 25px;
}
form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-bottom: 15px;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
/* label {
  width: 270px;
  display: block;
} */
.inputWrapperCheckbox {
  display: flex;
  gap: 10px;
  align-items: center;
}
.checkbox {
  display: flex;
  justify-self: flex-start;
  border: var(--gray-400) 1px solid;
  border-radius: 18px;
}

.inputGroupGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.checkboxLabel {
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: var(--gray-950);
  margin-bottom: 0;
}
.saveButton {
  background-color: var(--green-800);
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}
.addBtn {
  border: none;
}

.addButtonsWrapper {
  display: flex;
  gap: 10px;
}

.addButtonsWrapperOuter {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: end;
  margin-top: -20px;
}

.bottomSectionWrapper {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.pregnancyFormCheckboxLabelDisabled,
.autoSaveDisabled {
  color: #8f8fa1 !important;
}

.actionBtn:disabled {
  opacity: 0.5;
}
