.dashboard-bg {
  background-color: #fff;
  border-radius: 18px;
  padding: 20px;
  -webkit-filter: drop-shadow(0px 2px 6px rgba(120, 133, 178, 0.15));
  filter: drop-shadow(0px 2px 6px rgba(120, 133, 178, 0.15));
}

.small-label {
  font-size: 12px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.text-stratos {
  color: #060030;
}

.text-manatee {
  color: #8d8da3;
}

.text-royal-blue {
  color: #5d35e9;
}

.patient-detail .h4,
.patient-detail h4 {
  font-size: 20px;
}

.patient-detail .h5,
.patient-detail h5 {
  font-size: 18px;
}

.patient-detail .p,
.patient-detail p {
  font-size: 14px;
}

.patient-detail .p.small,
.patient-detail p.small {
  font-size: 12px;
}

.patient-detail h6.small {
  font-size: 15px;
}

.patient-detail .custom_btn {
  padding: 5px 32px;
  /* min-width: 140px; */
  font-size: 14px;
  font-weight: 600;
  border-radius: 500px;
  border: 1px solid var(--green-800);
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.custom_btn:hover {
  border: 1px solid var(--green-800);
}

.patient-detail {
  margin-top: 25px;
}

.patient-detail .page-title {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 600;
}

.dashBcontbody .patient-detail h3 {
  font-size: 24px;
  line-height: normal;
  font-weight: 600;
  margin-bottom: 5px;
}

.patient-detail-cardbox-container-1 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 39.7% 25px 28.8% 25px 28.8%;
  grid-template-columns: 39.7% 28.8% 28.8%;
  grid-gap: 25px;
}

.patient-detail-card {
  padding: 30px;
  height: 100%;
  overflow: hidden;
}

.patient-detail-card.bg {
  border-radius: 12px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.patient-header-wrap {
  position: relative;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.patient-header-wrap::before {
  content: "";
  position: absolute;
  left: -7%;
  bottom: 30px;
  width: 32px;
  height: 67px;
  background: url("../../../assets/images/ellipse.png") no-repeat;
  background-size: cover;
}

.patient-profile-img-wrap img,
.patient-profile-img-wrap .user-profile-image {
  width: 132px;
  height: 132px;
  border-radius: 10px;
  -o-object-fit: cover;
  object-fit: cover;
}

.patient-header-icon-wrap {
  width: 31px;
  height: 31px;
  background-color: #ebfbf5;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 5px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.patient-header-content-wrap {
  padding: 0 20px 0 30px;
}

.patient-header-content-wrap .p.small {
  font-size: 12px;
}

.patient-summary-wrap:not(:last-child) {
  margin-right: 25px;
}

.reason-visit-wrap {
  border-radius: 12px;
  border: 1.264px solid #f2f2fa;
  background: #fafafd;
  padding: 20px;
  margin-top: 25px;
}

.patient-bottom-wrap {
  position: relative;
  padding-top: 30px;
}

.patient-detail-card.patient-detail-summary-wrap {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.patient-detail-card.patient-detail-summary-wrap::before,
.patient-detail-card.patient-detail-summary-wrap::after {
  content: "";
  position: absolute;
  background-size: cover;
  z-index: -1;
}

.patient-detail-card.patient-detail-summary-wrap::before {
  background: url("../../../assets/images/half-circle.png") no-repeat;
  bottom: 0;
  left: 0;
  width: 200px;
  height: 14px;
}

.patient-detail-card.patient-detail-summary-wrap::after {
  background: url("../../../assets/images/shape.png") no-repeat;
  bottom: 0;
  right: 0;
  width: 127px;
  height: 118px;
}

.reason-visit-wrap p {
  color: #626282;
  font-size: 15px;
  line-height: 1.6;
}

.patient-header-wrap .custom_btn:not(:last-child) {
  margin-bottom: 15px;
}

.patient-block-wrap span {
  font-size: 15px;
  margin-bottom: 5px;
}

.patient-block-wrap:not(:last-child) {
  margin-bottom: 16px;
}

.patient-bottom-wrap > div {
  width: calc(100% / 2);
}

.patient-left-description::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 134px;
  background-color: rgba(0, 0, 0, 0.1);
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.patient-right-description {
  padding-left: 50px;
}

.btn.transaparent-btn {
  color: #060030;
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 400;
}

.btn.transaparent-btn:hover,
.btn.transaparent-btn:focus,
.btn.transaparent-btn:active {
  background-color: transparent;
  color: #060030;
  border-color: transparent;
}

.btn.transaparent-btn img {
  margin-left: 10px;
}

.documents-title-wrap {
  border-bottom: 1px solid #ececfa;
  padding-bottom: 18px;
}

.documents-link-wrap {
  margin-top: 30px;
}

.documents-link-wrap a {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  padding-left: 32px;
}

.documents-link-wrap a:not(:last-child) {
  margin-bottom: 20px;
}

.documents-link-wrap a:hover span,
.documents-link-wrap a:focus span,
.documents-link-wrap a:active span {
  text-decoration: underline;
}

.documents-link-wrap a::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--green-800);
  -ms-flex-negative: 0;
  flex-shrink: 0;
  left: 0;
  top: 4px;
  /* transform: translateY(-50%); */
}

.documents-link-wrap a svg {
  color: #b6c7d6;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.documents-link-wrap a:hover svg,
.documents-link-wrap a:focus svg,
.documents-link-wrap a:active svg {
  color: var(--green-800);
}

.patient-payments-details-wrap {
  margin-top: 30px;
  max-height: 360px;
  overflow-y: auto;
  margin-right: -30px;
  padding-right: 30px;
}

.consultation-wrap .icon-wrap {
  width: 34px;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
}

.consultation-wrap .icon-wrap.success {
  background: #ecfff8;
}

.consultation-wrap .icon-wrap.cancel {
  background: #fff0f6;
}

.consultation-wrap h6.small {
  line-height: 1.3;
}

.patient-payments-details-item .custom_btn.pay_now_btn {
  margin-top: 0;
  height: 30px;
  padding: 5px 22px;
  font-size: 10px;
  max-width: 100px;
  min-width: 100px;
  margin-left: 8px;
}

.patient-payments-details-item:not(:last-child) {
  margin-bottom: 25px;
}

.patient-payments-details-item .number {
  color: #6e6e77;
  font-weight: 400;
  font-size: 17px;
  line-height: 1.1%;
}

.amount-input-wrap {
  margin-top: 34px;
}

.amount-input-wrap .form-control {
  padding: 18px 120px 18px 16px;
  color: #8d8da3;
  border-radius: 13.996px;
  border: 1.166px solid var(--gray-400);
  background: #fff;
  font-weight: 500;
  font-size: 18px;
  height: auto;
}

.amount-input-wrap .form-control:focus {
  border-color: rgba(141, 141, 163, 0.5);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.amount-input-wrap input.form-control::-webkit-input-placeholder {
  color: #8d8da3;
}

.amount-input-wrap input.form-control::-moz-placeholder {
  color: #8d8da3;
}

.amount-input-wrap input.form-control:-ms-input-placeholder {
  color: #8d8da3;
}

.amount-input-wrap input.form-control::-ms-input-placeholder {
  color: #8d8da3;
}

.amount-input-wrap input.form-control::placeholder {
  color: #8d8da3;
}

.amount-input-wrap .custom_btn {
  border-radius: 6.998px;
  font-size: 11.663px;
  font-weight: 600;
  letter-spacing: -0.35px;
  padding: 8px 15px;
  position: absolute;
  right: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.patient-detail-cardbox-container-2 {
  font-family: "Montserrat", sans-serif !important;
  margin-top: 25px;
  margin-top: 25px;
}

.opportunities-wrap {
  margin-top: 25px;
}

.patients-tasks-wrap .documents-link-wrap a:hover span,
.patients-tasks-wrap .documents-link-wrap a:focus span,
.patients-tasks-wrap .documents-link-wrap a:active span {
  text-decoration: none;
}

.amount-input-wrap .custom_btn,
.opportunities-items .custom_btn {
  border-radius: 6.998px;
  font-size: 11.663px;
  font-weight: 600;
  letter-spacing: -0.35px;
  padding: 8px 12px;
}

.opportunities-items-wrap {
  margin-top: 25px;
}

.opportunities-items:not(:last-child) {
  margin-bottom: 20px;
}

.avatar-img-wrap {
  margin-right: 17px;
}

.avatar-img-wrap img {
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  width: 47.247px;
  height: 47.254px;
}

.visit-history-content-wrap {
  border-radius: 12px;
  border: 1.166px solid #dedeeb;
  background: #fafafd;
  padding: 10px 36px 10px 20px;
  margin-right: 12px;
}

.visit-history-content-wrap p {
  color: #8d8da3;
  line-height: 1.9;
}

.visit-history-content-wrap li {
  position: relative;
  color: #8d8da3;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.9;
  -webkit-padding-start: 25px;
  padding-inline-start: 25px;
}

.visit-history-content-wrap li::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  font-style: normal;
  background: url("../../../assets/images/tick1.svg") no-repeat;
  width: 18px;
  height: 18px;
  background-size: cover;
}

.visit-history .form-control {
  min-width: auto;
  background: #fafafd;
}

.custom_btn.save-note {
  padding: 9px 22px;
  font-size: 10px;
  letter-spacing: -0.2px;
  min-width: 75px;
  display: block;
  height: auto;
}

.custom_btn.save-note:hover {
  color: #fff;
  background-color: #069f68;
}

.custom_btn.danger {
  background-color: #fb163f;
  border-color: transparent;
}
.documents-list-wrap .custom_btn.danger {
  font-size: 10px;
  font-weight: 600;
  margin-left: 10px;
  padding: 4px 16px;
  height: auto;
}

.custom_btn.danger:hover {
  color: #fff;
  background-color: #cb082c;
  border-color: #fb163f;
}

.todo-btn-wrap > div:not(:last-child) {
  margin-right: 5px;
}
.todo-btn-wrap .custom_btn {
  min-width: 100px;
  max-width: 100px;
}

.todo-btn-wrap {
  margin-top: 10px;
  /* margin-left: 20px; */
}

.custom-accordion-wrapper {
  margin-top: 42px;
  /* max-height: 300px;
  overflow-y: auto; */
  margin-right: -30px;
  padding-right: 30px;
}
.custom-accordion-wrapper .accordion::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.custom-accordion-wrapper .accordion {
  border-radius: 9px;
  border: 1px solid var(--gray-400);
  padding: 15px;
  max-height: 300px;
  overflow-y: auto;
}

/* .accordion-header-title-wrap {
  margin-right: 55px;
} */

.patient-payments-details-wrap::-webkit-scrollbar,
.custom-accordion-wrapper::-webkit-scrollbar,
.all-documents-link-wrap
  .documents-link-wrap.documents-list-items-wrap::-webkit-scrollbar {
  height: 2px;
  width: 2px;
}

.patient-payments-details-wrap::-webkit-scrollbar-thumb,
.custom-accordion-wrapper::-webkit-scrollbar-thumb {
  background: var(--green-800);
}

.patient-payments-details-wrap::-webkit-scrollbar-track,
.custom-accordion-wrapper::-webkit-scrollbar-track {
  background: #ffffff80;
}

.custom-accordion-wrapper .accordion-item {
  border-color: #ececfa;
  position: relative;
  -webkit-padding-start: 32px;
  padding-inline-start: 32px;
  /* margin-right: 30px; */
  border-bottom: 0;
  margin-bottom: 15px;
}

.custom-accordion-wrapper .accordion-item:not(:last-child) {
  margin-bottom: 30px;
}

.custom-accordion-wrapper .accordion-button {
  padding: 0 0 12px 0;
  color: #060030;
  font-size: 16px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 600;
  letter-spacing: -0.16px;
  position: relative;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-bottom: 1px solid #ececfa;
  line-height: normal;
}

.custom-accordion-wrapper .accordion-button::before {
  content: "";
  position: absolute;
  left: -30px;
  top: 7px;
  background-color: var(--green-800);
  width: 12px;
  height: 12px;
  z-index: 1;
  border-radius: 50%;
}

.custom-accordion-wrapper .label-wrap {
  display: inline-block;
  color: var(--green-800);
  font-size: 12px;
  font-weight: 600;
  line-height: 2;
  border-radius: 17px;
  background: #ebfbf5;
  padding: 2px 13px;
  text-align: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 20px;
}

.custom-accordion-wrapper .accordion-button::after {
  content: "Expand";
  width: auto;
  height: auto;
  background-image: none;
  color: var(--green-800);
  text-align: right;
  font-size: 14px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  font-weight: 500;
  position: absolute;
  right: 0;
  top: 5px;
  display: none;
}
.accordion-text-wrap {
  color: var(--green-800);
  font-size: 14px;
  margin-left: 10px;
}

/* .custom-accordion-wrapper .accordion-header {
    padding: 12px 0;
  } */
.custom-accordion-wrapper .accordion-button:not(.collapsed) {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
  content: "Reduce";
}

.custom-accordion-wrapper .accordion-body {
  border-radius: 12px;
  border: 1.166px solid #dedeeb;
  background: #fafafd;
  margin-bottom: 15px;
  color: #8d8da3;
  font-size: 13px;
  height: 100%;
  font-weight: 500;
  line-height: 1.7;
  padding: 10px 15px;
}

.custom-accordion-wrapper .accordion-button:not(.collapsed) {
  border-bottom: none;
}

.custom-accordion-wrapper .accordion-button p {
  line-height: 1.9;
}

.custom-accordion-wrapper .accordion-button:not(.collapsed) p {
  display: none;
}

/* .custom-accordion-wrapper .accordion-button:not(.collapsed) .label-wrap {
  display: none;
} */

.edit-btn-wrap:not(:last-child) {
  margin-right: 5px;
}
.edit-btn-wrap .custom_btn {
  /* border-radius: 6.998px; */
  font-size: 10px;
  font-weight: 600;
  /* margin-left: 10px; */
  height: auto;
  padding: 4px 16px;
}

/* Checkbox Design Start*/
.custom-checkbox-wrap .ant-checkbox-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Montserrat", sans-serif !important;
}

.ant-checkbox {
  background: #fff;
}

.custom-checkbox-wrap .ant-checkbox .ant-checkbox-inner {
  width: 22px;
  height: 22px;
  border: 2px solid var(--gray-400);
  border-radius: 5px;
}

.custom-checkbox-wrap .ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border: 2px solid var(--gray-400);
}

.custom-checkbox-wrap .ant-checkbox-checked .ant-checkbox-inner,
.custom-checkbox-wrap.ant-checkbox-checked .ant-checkbox-input {
  background-color: var(--green-800) !important;
  border-color: var(--green-800) !important;
}

.custom-checkbox-wrap .ant-checkbox + span {
  color: #8d8da3;
  font-size: 16px;
  font-weight: 500;
}

.custom-checkbox-wrap .ant-checkbox .ant-checkbox-inner:after {
  width: 7px;
  height: 12px;
}

.custom-checkbox-wrap > label:not(:last-child) {
  margin-right: 16px;
}

/* Checkbox Design End*/

/* Tabs Design Start */
.documents-tabs-wrap .ant-tabs {
  font-family: "Montserrat", sans-serif !important;
}

.documents-tabs-wrap
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  > .ant-tabs-tab:first-child {
  margin-right: 0px;
}

.documents-tabs-wrap .ant-tabs-tabpane {
  padding: 0;
}

.documents-tabs-wrap .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(2, 259px);
  width: 100%;
  grid-gap: 20px;
}

.documents-tabs-wrap .ant-tabs-nav-more {
  display: none;
}

.documents-tabs-wrap .ant-tabs .ant-tabs-tab {
  border-radius: 6px;
  border: 1px solid #e5eef7;
  padding: 16px 20px;
  /* min-width: 259px; */
  color: #25204a;
  font-size: 16px;
  background: #fff;
  font-weight: 600;
}

.documents-tabs-wrap .ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
  border-color: var(--green-800);
  background: #ebfbf5;
}

.documents-tabs-wrap
  .ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #25204a;
}

.documents-tabs-wrap .ant-tabs-ink-bar.ant-tabs-ink-bar-animated,
.documents-tabs-wrap .ant-tabs-top > .ant-tabs-nav::before {
  display: none;
}

.documents-tabs-wrap .ant-tabs .ant-tabs-tab .icon-wrap {
  width: 31px;
  height: 31px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #ebfbf5;
  border-radius: 50%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 10px;
  color: var(--green-800);
}

.documents-tabs-wrap .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .icon-wrap,
.documents-tabs-wrap .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .icon-wrap {
  background-color: var(--green-800);
  color: #fff;
}

.documents-tabs-wrap .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 0px;
}

.documents-tabs-wrap .ant-tabs-top > .ant-tabs-nav {
  margin: 0 120px 0 0;
  border-bottom: 1px solid #ececfa;
  padding-bottom: 25px;
}

.all-documents-link-wrap {
  margin: 0 -30px -30px;
  background: rgba(217, 217, 217, 0.1);
}

.all-documents-link-wrap .documents-link-wrap a {
  position: relative;
  background: rgba(217, 217, 217, 0.1);
  padding: 15px 25px 15px 65px;
}

.all-documents-link-wrap .documents-link-wrap a:not(:last-child) {
  border-style: solid;
  border-color: #ececfa;
  border-width: 1px 0px 1px 0;
}

.all-documents-link-wrap .documents-link-wrap a::before {
  left: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.all-documents-link-wrap .documents-link-wrap a:not(:last-child) {
  margin-bottom: 0px;
}

.all-documents-link-wrap .documents-link-wrap.documents-list-items-wrap {
  max-height: 230px;
  overflow-y: auto;
}

.tabs-button-wrap {
  position: absolute;
  top: 40px;
  right: 25px;
}

.patient-detail-card .ant-tabs-nav-operations .ant-tabs-nav-more img {
  width: 31px !important;
  height: auto;
}

.patient-payments-details-wrap.tabs-wrap {
  max-height: 250px;
  overflow-y: auto;
}

/* Tabs Design End */
.no-record-text {
  font-weight: 600;
  font-size: 15px !important;
  padding: 10px 0;
  color: #060030;
  /* border-top: 1px solid var(--gray-400); */
}
.no-record-text.spacing-wrap {
  padding-left: 15px;
}

/* Payments Modal Design Start */
.payments-modal {
  width: 888px !important;
  font-family: "Montserrat", sans-serif !important;
}

.payments-modal .ant-modal-content {
  padding: 0;
  border-radius: 18px;
}

.payments-modal .ant-modal-header {
  font-size: 24px;
  font-weight: 700;
  padding: 19px 30px;
  border-radius: 18px 18px 0 0;
  margin-bottom: 0px;
}
.payments-modal .MuiStack-root {
  padding-top: 0;
}
.payments-modal fieldset {
  display: none;
}
.payments-modal .MuiInputBase-root {
  border-radius: 12px;
  font-size: 15px;
  background: var(--white);
  border: 1px solid var(--gray-400);
  height: 50px;
}
.payments-modal .MuiStack-root input {
  padding: 14.5px 14px;
  color: #060030;
  font-family: "Montserrat", sans-serif !important;
}

.payments-modal .ant-modal-header .ant-modal-title {
  color: var(--stratos);
  font-weight: 600;
  font-size: 24px;
}

.payments-modal .ant-modal-close {
  color: rgba(0, 0, 0, 1);
  top: 25px;
}

.payments-modal .ant-modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-top: 1px solid var(--gray-400);
  margin: 0 34px;
  padding: 30px 0 46px;
}

.payments-modal .ant-modal-footer > .ant-btn + .ant-btn {
  -webkit-margin-start: 0px;
  margin-inline-start: 0px;
  margin-right: 20px;
}

.payments-modal .ant-btn {
  -webkit-margin-start: 0px;
  margin-inline-start: 0px;
  width: auto;
  border-radius: 30px;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 18px;
  font-weight: 600;
  height: auto;
  font-family: "Montserrat", sans-serif !important;
  padding: 17px 35px;
  line-height: normal;
}

.payments-modal .ant-btn.ant-btn-primary {
  background-color: var(--green-800);
  color: #fff;
}

.payments-modal .ant-btn.ant-btn-default {
  background-color: #ececfa;
  color: #78789b;
}

.payments-modal .ant-btn-default:hover,
.payments-modal .ant-btn-default:focus,
.payments-modal .ant-btn-default:active {
  background-color: #cccced !important;
  color: #78789b !important;
}

.payments-modal .ant-btn-primary:hover,
.payments-modal .ant-btn-primary:focus,
.payments-modal .ant-btn-primary:active {
  background-color: #04ad70 !important;
}

.payments-modal .ant-modal-body {
  padding: 25px 34px 50px;
  /* max-height: 700px;
  overflow-y: auto; */
  font-family: "Montserrat", sans-serif !important;
}

.payments-modal .patients-info {
  border: 1px solid #dedeeb;
  border-radius: 12px;
  overflow: hidden;
}

.payments-modal .patients-payments-title {
  background: #fafafd;
  padding: 20px 25px;
  /* border-right: 1px solid #dedeeb; */
}
.payments-modal .patients-payments-title {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}
.record-text-wrap {
  margin-top: 10px;
}

.payments-modal .patients-payments-desc {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.payments-modal .patients-payments-desc {
  -ms-flex-pack: distribute;
  justify-content: space-around;
  text-align: center;
}

.payments-modal .patients-payments-desc > div:first-child {
  border-bottom: 1px solid #dedeeb;
  padding: 10px;
}

.payments-modal .patients-payments-desc > div:nth-child(2) {
  padding: 10px;
}

.payments-modal .patients-payments-title p.small {
  color: rgba(6, 0, 48, 50%);
}

.payments-modal form .grid-item {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.custom-input-wrap {
  width: 100%;
  position: relative;
}

.select-icon-wrap {
  content: "";
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.custom-input-wrap .form-control {
  height: 50px;
}

.custom-input-wrap .ant-input {
  border-radius: 12px;
  font-size: 15px;
  padding: 12.3px 20px;
  height: auto;
  min-height: auto;
  color: #060030;
  font-weight: 400;
  background: var(--white);
  border: 1px solid var(--gray-400);
  display: inline-block;
  font-family: "Montserrat", sans-serif !important;
}

.custom-label-wrap .h6.small,
.custom-label-wrap h6.small {
  font-size: 15px;
}

.custom-input-wrap .ant-select-selector {
  padding: 0 20px 0 17px !important;
  border: 1px solid var(--gray-400) !important;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif !important;
}

.custom-input-wrap .ant-select-single {
  font-size: 16px;
  height: auto;
  width: 100%;
}

.custom-input-wrap .ant-select-single .ant-select-selection-item {
  padding: 10px 18px 10px 0;
  font-size: 15px;
  font-weight: 400;
}

.custom-input-wrap .ant-picker {
  padding: 13px 20px 13px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 15px;
  border-radius: 12px;
  line-height: normal;
  font-weight: 400;
  font-family: "Montserrat", sans-serif !important;
  width: 100%;
  border: 1px solid var(--gray-400) !important;
}

.custom-input-wrap .ant-picker-outlined:hover {
  border-color: transparent;
  background-color: #ffffff;
}

.payments-modal .form-spacing:not(:last-child) {
  margin-bottom: 30px;
}

.payments-modal .amount-wrap {
  padding: 15px 20px;
  border-radius: 12px;
  background: #e7fff6;
  margin: 25px 0 30px;
}

.payments-modal .amount-wrap span {
  font-size: 16px;
}

.payments-modal .amount-wrap h4 {
  color: var(--green-800);
}

.payments-modal .ant-modal-body::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}

.payments-modal .ant-picker .ant-picker-suffix {
  color: #8d8da3;
}

.accordion-header-title-wrap .header-title {
  /* max-width: 260px; */
  margin-right: 15px;
}

/* Tabs Design Start */
.documents-tabs-wrap.tabs-wrap {
  margin-top: 20px;
}
.documents-tabs-wrap.tabs-wrap .ant-tabs .ant-tabs-tab {
  border-radius: 6px;
  border: 1px solid #e5eef7;
  padding: 10px 15px;
  min-width: auto;
  color: #25204a;
  font-size: 16px;
  background: #fff;
  font-weight: 500;
}
.documents-tabs-wrap.tabs-wrap .ant-tabs-top > .ant-tabs-nav {
  margin: 0;
  border-bottom: none;
  padding-bottom: 0px;
}
.documents-tabs-wrap.tabs-wrap .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 0px;
}
.consultation-wrap .custom-checkbox-wrap.spacing-wrap {
  margin-right: 24px;
  margin-top: 3px;
}
.custom_btn.pay_now_btn {
  width: 100%;
  margin-top: 24px;
  padding: 6px 22px;
}
.documents-tabs-wrap.tabs-wrap .ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
  border-color: var(--green-800);
  background: #ebfbf5;
}
.documents-tabs-wrap.tabs-wrap .ant-tabs-nav-operations {
  display: none;
}

.documents-tabs-wrap.tabs-wrap .ant-tabs-nav-list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 10px 1fr !important;
  grid-template-columns: repeat(2, 1fr) !important;
  width: 100%;
  grid-gap: 10px !important;
}
/* Tabs Design End */

/* Payments Modal Design End */

/* Responsive media query */
@media only screen and (max-width: 1799px) {
  .patient-detail .page-title {
    font-size: 28px;
  }

  .dashBcontbody .patient-detail h3 {
    font-size: 22px;
  }

  .patient-detail .h4,
  .patient-detail h4 {
    font-size: 18px;
  }

  .patient-detail .p,
  .patient-detail p {
    font-size: 13px;
  }

  .patient-detail .h5,
  .patient-detail h5 {
    font-size: 16px;
  }

  .patient-profile-img-wrap img,
  .patient-profile-img-wrap .user-profile-image {
    width: 110px;
    height: 110px;
  }

  .patient-header-content-wrap {
    padding: 0 20px 0 20px;
  }

  .patient-summary-wrap:not(:last-child) {
    margin-right: 12px;
  }

  .patient-header-content-wrap h6 {
    font-size: 13px;
  }

  .patient-header-wrap .custom_btn {
    /* padding: 10px 16px; */
    font-size: 12px;
    height: 35px;
  }

  .patient-detail-card {
    padding: 20px;
  }

  .patient-payments-details-wrap {
    margin-right: -20px;
    padding-right: 20px;
  }

  .reason-visit-wrap p {
    font-size: 13px;
  }

  .documents-title-wrap {
    padding-bottom: 15px;
  }

  .documents-link-wrap a {
    font-size: 15px;
    padding-left: 26px;
  }

  .patient-bottom-wrap h6,
  .patient-block-wrap span {
    font-size: 13px;
  }

  .patient-bottom-wrap::before,
  .patient-bottom-wrap::after {
    bottom: -57%;
  }

  .patient-header-wrap::before {
    left: -9%;
  }

  .custom-accordion-wrapper {
    margin-right: -20px;
    padding-right: 20px;
  }

  .patient-right-description {
    padding-left: 40px;
  }

  .tabs-button-wrap {
    top: 35px;
    right: 15px;
  }

  .all-documents-link-wrap {
    margin: 0 -20px -20px;
  }

  .all-documents-link-wrap .documents-link-wrap a {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1599px) {
  .patient-detail .page-title {
    font-size: 26px;
  }

  .dashBcontbody .patient-detail h3 {
    font-size: 20px;
  }

  .patient-detail .h4,
  .patient-detail h4 {
    font-size: 16px;
  }

  .patient-detail .h5,
  .patient-detail h5 {
    font-size: 15px;
  }

  /* .payments-modal .ant-modal-body {
    max-height: 600px;
  } */
  .accordion-header-title-wrap .header-title {
    font-size: 12px;
  }
  .patient-header-content-wrap .h3 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 1439px) {
  .dashBcontbody .patient-detail h3 {
    font-size: 18px;
  }

  .patient-right-description {
    padding-left: 30px;
  }

  .patient-profile-img-wrap img,
  .patient-profile-img-wrap .user-profile-image {
    width: 90px;
    height: 90px;
  }

  .patient-header-wrap .custom_btn {
    padding: 9px 20px;
    font-size: 10px;
  }

  .custom-accordion-wrapper .accordion-item:not(:last-child) {
    margin-bottom: 20px;
  }

  .btn.transaparent-btn {
    font-size: 15px;
  }

  .consultation-wrap h6.small {
    font-size: 13px;
  }

  .patient-payments-details-item .number {
    font-size: 15px;
  }

  .documents-link-wrap a {
    font-size: 13px;
    padding-left: 20px;
  }

  .documents-link-wrap a::before {
    width: 8px;
    height: 8px;
    top: 6px;
  }

  .visit-history-content-wrap li {
    font-size: 12px;
  }

  .visit-history-content-wrap li::before {
    top: 3px;
    width: 14px;
    height: 14px;
  }

  .visit-history-content-wrap li {
    -webkit-padding-start: 20px;
    padding-inline-start: 20px;
  }

  .amount-input-wrap .custom_btn,
  .opportunities-items .custom_btn {
    font-size: 9.663px;
    padding: 7px 10px;
  }

  .amount-input-wrap .form-control {
    font-size: 14px;
  }

  .avatar-img-wrap img {
    width: 40px;
    height: 40px;
  }

  .avatar-img-wrap {
    margin-right: 10px;
  }

  /* .patient-bottom-wrap::before,
  .patient-bottom-wrap::after {
    bottom: -45%;
  } */
  .documents-tabs-wrap .ant-tabs .ant-tabs-tab,
  .documents-table-wrap .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 15px;
  }

  /* .documents-tabs-wrap .ant-tabs .ant-tabs-tab {
    min-width: 220px;
  } */

  .payments-modal .form-spacing:not(:last-child) {
    margin-bottom: 25px;
  }

  .payments-modal .ant-modal-footer {
    padding: 30px 0 40px;
  }
  .patient-header-content-wrap .h3 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1365px) {
  .patient-detail .page-title {
    font-size: 22px;
  }
  .no-record-text {
    font-size: 14px !important;
  }

  .dashBcontbody .patient-detail h3 {
    font-size: 16px;
  }

  .btn.transaparent-btn {
    padding: 0;
  }

  .btn.transaparent-btn img {
    margin-left: 5px;
  }

  .patient-profile-img-wrap img {
    width: 80px;
    height: 80px;
  }

  .opportunities-items h6.small {
    font-size: 14px;
  }

  .opportunities-items .p {
    font-size: 11px;
  }

  .patient-detail-cardbox-container-1,
  .patient-detail-cardbox-container-2 {
    grid-gap: 15px;
  }

  .patient-detail-cardbox-container-2 {
    margin-top: 15px;
  }

  .reason-visit-wrap,
  .opportunities-wrap {
    margin-top: 15px;
  }

  .custom-accordion-wrapper .accordion-button::before {
    left: -20px;
    width: 8px;
    height: 8px;
  }

  .patient-bottom-wrap::before,
  .patient-bottom-wrap::after {
    bottom: -27%;
  }

  .patient-header-content-wrap {
    padding: 0 15px 0 15px;
  }

  .patient-header-content-wrap h6 {
    font-size: 10px;
  }

  .ant-tabs-nav-operations .ant-tabs-nav-more img {
    width: 26px !important;
  }

  .payments-modal .ant-modal-header {
    padding: 16px 20px;
    border-radius: 12px 12px 0 0;
  }

  .payments-modal .ant-modal-header .ant-modal-title {
    font-size: 21px;
  }

  .payments-modal .ant-modal-close {
    top: 20px;
  }

  .payments-modal .ant-modal-body {
    padding: 20px 20px 30px;
    /* max-height: 420px; */
  }

  .payments-modal .ant-modal-footer {
    margin: 0 20px;
    padding: 20px 0 30px;
  }

  .payments-modal .ant-btn {
    font-size: 16px;
    padding: 14px 34px;
  }
  .custom-accordion-wrapper .accordion-button::after {
    font-size: 12px;
    top: 8px;
  }
  .patient-header-content-wrap .h3 {
    font-size: 20px;
  }
  .patient-payments-details-item .custom_btn.pay_now_btn {
    padding: 5px 12px;
    max-width: 80px;
    min-width: 80px;
  }
}

@media only screen and (max-width: 1199px) {
  .patient-detail .page-title {
    font-size: 20px;
  }

  .patient-detail-cardbox-container-1 {
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
  }

  .patient-detail-cardbox-container-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
  }

  .patient-header-wrap::before {
    left: -5%;
  }

  .patient-bottom-wrap::before,
  .patient-bottom-wrap::after {
    bottom: -12%;
  }

  .patient-header-wrap .custom_btn:not(:last-child) {
    margin-bottom: 15px;
  }
  .documents-tabs-wrap .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    grid-template-columns: repeat(2, 230px);
  }
}

@media only screen and (max-width: 991px) {
  .patient-detail .page-title {
    margin-bottom: 20px;
  }

  .custom-accordion-wrapper .accordion-button::after {
    font-size: 12px;
    top: 5px;
  }

  .custom-accordion-wrapper .accordion-button .label-wrap {
    font-size: 10px;
    padding: 2px 8px;
  }

  .custom-accordion-wrapper .accordion-button {
    font-size: 15px;
  }

  .patient-payments-details-item:not(:last-child) {
    margin-bottom: 18px;
  }

  .documents-link-wrap {
    margin-top: 20px;
  }

  .patient-header-wrap::before {
    background: url("../../../assets/images/ellipse1.png") no-repeat;
    width: 80px;
    height: 80px;
    left: -40px;
  }

  .patient-detail-card.patient-detail-summary-wrap::before {
    background: url("../../../assets/images/half-circle1.png") no-repeat;
    width: 104px;
    height: 13px;
    bottom: 0;
    left: 40px;
  }

  .patient-detail-card.patient-detail-summary-wrap::after {
    background: url("../../../assets/images/shape1.png") no-repeat;
    width: 106px;
    height: 95px;
    bottom: 0;
    right: 0;
  }

  .payments-modal .patients-info {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .payments-modal .patients-payments-title,
  .payments-modal .patients-payments-desc {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .payments-modal .patients-payments-title {
    padding: 20px;
  }

  .payments-modal form .grid-item {
    -ms-grid-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
  }
  .payments-modal .form-spacing:not(:last-child) {
    margin-bottom: 15px;
  }

  .payments-modal .patient-detail .h4,
  .payments-modal .patient-detail h4 {
    font-size: 16px !important;
  }

  .custom-accordion-wrapper .accordion-button::after {
    left: 430px;
  }
  .accordion-text-wrap {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .custom_btn.save-note,
  .edit-btn-wrap .custom_btn {
    margin-top: 12px;
    margin-left: 0;
    padding: 6px 12px;
  }

  .patient-header-wrap .custom_btn:not(:last-child) {
    margin-bottom: 10px;
  }

  .visit-history-content-wrap {
    margin-right: 0px;
    padding: 10px;
  }

  /* .patient-header-wrap::before {
      left: -9%;
    } */
  .patient-detail .page-title {
    font-size: 26px;
  }

  .opportunities-wrap {
    margin-top: 25px;
  }

  .patient-detail-card {
    height: auto;
  }

  .documents-tabs-wrap .ant-tabs .ant-tabs-tab {
    padding: 14px 16px;
  }

  .all-documents-link-wrap .documents-link-wrap a {
    padding: 12px 25px 12px 40px;
    font-size: 13px;
  }

  .all-documents-link-wrap .documents-link-wrap a::before {
    left: 20px;
  }

  .tabs-button-wrap {
    top: 90px;
    right: unset;
    left: 15px;
  }

  .documents-tabs-wrap .ant-tabs-top > .ant-tabs-nav {
    margin: 0 0px 0 0;
    padding-bottom: 50px;
  }

  .accordion-header-title-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .accordion-header-title-wrap .time-wrap {
    margin-left: 0px !important;
    padding-left: 0px !important;
    margin-top: 5px;
  }

  .todo-btn-wrap {
    margin-left: 0;
    margin-top: 12px;
  }

  .todo-btn-wrap .custom_btn.save-note {
    margin-top: 0px;
    margin-left: 0;
    padding: 6px 12px;
  }

  .time-wrap > .edit-btn-wrap:not(:last-child) {
    margin-right: 5px;
  }

  .time-wrap > .edit-btn-wrap .custom_btn {
    min-width: 70px;
  }
  .edit-btn-wrap .custom_btn {
    font-size: 9px;
  }

  .custom-accordion-wrapper .accordion-button::before {
    top: 14px;
  }

  .custom-accordion-wrapper .accordion-button::after {
    left: unset;
    right: 0;
  }
  .consultation-wrap .custom-checkbox-wrap.spacing-wrap {
    margin-right: 12px;
  }
  .documents-tabs-wrap.tabs-wrap .ant-tabs .ant-tabs-tab {
    /* min-width: 160px; */
    font-size: 14px;
  }
  .custom-accordion-wrapper .accordion-button .label-wrap {
    margin-right: 10px;
  }
  .documents-tabs-wrap .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 575px) {
  .patient-detail .btn-wrap {
    margin-top: 15px;
  }

  .dashBcontbody .patient-detail h3 {
    font-size: 24px;
  }

  .patient-detail .h4,
  .patient-detail h4 {
    font-size: 18px;
  }

  .patient-detail .p.small,
  .patient-detail p.small {
    font-size: 10px;
  }

  .patient-left-description::after {
    display: none;
  }

  .patient-bottom-wrap > div {
    width: 100%;
  }

  .patient-right-description {
    padding-left: 0px;
    margin-top: 12px;
  }

  .patient-block-wrap:not(:last-child) {
    margin-bottom: 12px;
  }

  .custom-accordion-wrapper .accordion-item {
    -webkit-padding-start: 15px;
    padding-inline-start: 15px;
  }

  .custom-accordion-wrapper .accordion-button::before {
    left: -16px;
    width: 6px;
    height: 6px;
  }

  .custom-accordion-wrapper .accordion-button {
    font-size: 14px;
  }

  .btn.transaparent-btn {
    font-size: 14px;
  }

  .patient-header-wrap .custom_btn:not(:last-child) {
    margin-bottom: 0px;
    margin-right: 10px;
  }

  .patient-header-content-wrap {
    padding: 0 0px 0 12px;
  }

  .patient-profile-img-wrap img {
    width: 80px;
    height: 80px;
  }

  .custom-accordion-wrapper .label-wrap {
    margin-left: 0px;
    font-size: 8px;
    font-weight: 500;
    line-height: 1.8;
    margin-top: 10px;
  }

  .patient-header-content-wrap {
    width: 100%;
    text-align: center;
    padding: 0;
  }

  .patient-profile-img-wrap img {
    width: 130px;
    height: 130px;
    margin-bottom: 20px;
  }

  .patient-header-content-wrap h3 {
    font-size: 24px;
  }

  .patient-header-content-wrap .p.small {
    font-size: 12px;
    margin-bottom: 20px !important;
  }

  .patient-header-content-wrap h6 {
    font-size: 16px;
  }

  .patient-summary-wrap:not(:last-child) {
    margin-right: 16px;
  }

  .patient-header-wrap .custom_btn {
    padding: 10px 25px;
    font-size: 14px;
    height: 40px;
  }

  /* .patient-header-wrap::before {
      bottom: 130px;
    }
    .patient-bottom-wrap::before,
    .patient-bottom-wrap::after {
      bottom: -8%;
    } */
  .patient-bottom-wrap h6 {
    font-size: 16px;
  }

  .reason-visit-wrap h5 {
    font-size: 16px;
  }

  .reason-visit-wrap p {
    font-size: 12px;
    margin-top: 10px;
  }

  .patient-detail-cardbox-container-1 {
    grid-gap: 25px;
  }

  .btn.transaparent-btn img {
    width: 22%;
  }

  .patient-detail-card h4 {
    font-size: 18px;
  }

  .documents-link-wrap a {
    font-size: 16px;
    padding-left: 20px;
  }

  .documents-link-wrap a::before {
    width: 6px;
    height: 6px;
  }

  .consultation-wrap .icon-wrap {
    width: 30px;
    height: 30px;
    margin-right: 10px !important;
  }

  .consultation-wrap h6.small:nth-child(1) {
    font-size: 14px;
  }

  .consultation-wrap h6.small:nth-child(2) {
    font-size: 12px;
  }

  .patient-payments-details-item .number {
    font-size: 12px;
  }

  .patient-payments-details-item
    .custom-checkbox-wrap
    .ant-checkbox
    .ant-checkbox-inner {
    width: 17px;
    height: 17px;
  }

  .patient-payments-details-item
    .custom-checkbox-wrap
    .ant-checkbox
    .ant-checkbox-inner:after {
    width: 5px;
    height: 9px;
  }

  .amount-input-wrap .form-control {
    padding: 10px 100px 10px 10px;
    font-size: 14px;
    border-radius: 6px;
  }

  .amount-input-wrap .custom_btn {
    padding: 6px 11px;
    right: 8px;
    font-size: 10px;
  }

  .visit-history-content-wrap li::before {
    width: 14px;
    height: 14px;
  }

  .visit-history-content-wrap li {
    -webkit-padding-start: 18px;
    padding-inline-start: 18px;
  }

  .visit-history-content-wrap {
    padding: 20px;
  }

  .custom-accordion-wrapper .accordion-button::after {
    font-size: 10px;
  }

  .opportunities-items .p {
    font-size: 10px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
    display: block;
  }

  .avatar-img-wrap img {
    width: 32px;
    height: 32px;
  }

  .opportunities-items .custom_btn {
    font-size: 8px;
    padding: 6px 10px;
  }

  .patient-header-wrap::before {
    bottom: 130px;
  }

  .ant-tabs-nav-operations .ant-tabs-nav-more img {
    width: 22px !important;
  }

  /* .documents-tabs-wrap .ant-tabs-top > .ant-tabs-nav {
    margin: 0 100px 0 0;
  } */

  .custom-input-wrap .ant-select-selector {
    padding: 0 15px 0 17px !important;
  }

  .custom-input-wrap .ant-picker {
    padding: 13px 15px 13px;
  }

  .custom-input-wrap .ant-input {
    padding: 12.3px 15px;
  }

  .payments-modal .ant-modal-footer > .ant-btn + .ant-btn {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .payments-modal .ant-modal-footer > .ant-btn {
    width: 100%;
  }

  .payments-modal .ant-modal-footer {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .patients-payments-desc .h6 {
    font-size: 14px;
  }

  .patients-payments-desc h6.small {
    font-size: 14px;
  }

  .accordion-header-title-wrap .header-title {
    /* max-width: 120px; */
    font-size: 12px;
    margin-right: 10px;
  }
  .patient-detail-card .modal-btn-wrap {
    display: -ms-grid !important;
    display: grid !important;
    /* -ms-grid-columns: (1fr) [2]; */
    grid-template-columns: repeat(2, 1fr);
  }
  .patient-profile-img-wrap {
    margin-bottom: 10px;
  }
  .custom-accordion-wrapper .accordion-button::after {
    top: 8px;
  }
  .documents-tabs-wrap .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
  .tabs-button-wrap {
    top: 170px;
  }
}

@media only screen and (max-width: 480px) {
  .custom-accordion-wrapper .accordion-body {
    font-size: 10px;
  }

  .visit-history-content-wrap p {
    font-size: 12px;
  }

  .visit-history-content-wrap p {
    line-height: 1.5;
  }

  .patient-bottom-wrap::after {
    right: -30px;
  }

  .opportunities-items-wrap {
    margin-top: 20px;
  }

  .all-documents-link-wrap .documents-link-wrap a {
    padding: 12px 14px 12px 36px;
  }

  /* .documents-tabs-wrap .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 12px;
  } */

  .custom-accordion-wrapper .accordion-button::after {
    left: 220px;
  }
  .todo-btn-wrap .custom_btn {
    min-width: 90px;
    max-width: 90px;
  }
}

@media only screen and (max-width: 414px) {
  /* .patient-header-wrap::before {
      left: -13%;
    } */
  .accordion-text-wrap {
    margin-left: 0px;
    margin-top: 7px;
  }
  .dashBcontbody .patient-detail h3 {
    font-size: 20px;
  }

  .patient-detail .h4,
  .patient-detail h4 {
    font-size: 16px;
  }

  .patient-bottom-wrap::after {
    width: 98px;
    height: 98px;
  }

  .patient-header-wrap .custom_btn {
    font-size: 12px;
    height: 35px;
  }

  .patient-header-icon-wrap img {
    width: 50%;
  }

  .btn.transaparent-btn img {
    width: 27%;
  }

  .avatar-img-wrap img {
    width: 35px;
    height: 35px;
  }

  /* .opportunities-items {
      flex-wrap: wrap;
    } */
  .opportunities-items .btn-wrap {
    margin-top: 10px;
  }

  .opportunities-items .p {
    font-size: 10px;
  }

  .opportunities-items h6.small {
    font-size: 13px;
  }

  .documents-link-wrap a:not(:last-child) {
    margin-bottom: 14px;
  }

  .custom-accordion-wrapper .accordion-button .label-wrap {
    font-size: 8px;
    padding: 2px 6px;
    margin-left: 5px;
  }

  .documents-link-wrap a {
    font-size: 14px;
    padding-left: 15px;
  }

  .visit-history-content-wrap li {
    -webkit-padding-start: 23px;
    padding-inline-start: 23px;
    line-height: 1.4;
  }

  .patient-detail-card h4 {
    font-size: 16px;
  }

  /* .custom-accordion-wrapper .accordion-button {
      font-size: 13px;
    } */
  .patient-detail .page-title {
    font-size: 19px;
  }

  .patient-header-content-wrap h3 {
    font-size: 20px;
  }

  .btn.transaparent-btn {
    font-size: 12px;
  }

  .patient-bottom-wrap h6 {
    font-size: 14px;
  }

  .header-title {
    max-width: 140px;
  }

  .amount-input-wrap .custom_btn {
    padding: 6px 11px;
    font-size: 8px;
  }

  /* .documents-tabs-wrap .ant-tabs .ant-tabs-tab {
    min-width: 170px;
  } */

  .documents-tabs-wrap .ant-tabs .ant-tabs-tab,
  .documents-table-wrap .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 14px;
  }

  .custom-accordion-wrapper .accordion-button::after {
    left: 170px;
  }
  .accordion-header-title-wrap {
    margin-bottom: 8px !important;
  }
  .accordion-header-title-wrap .header-title {
    /* max-width: 100px; */
    margin-right: 8px;
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 350px) {
  .consultation-wrap h6.small:nth-child(1) {
    font-size: 13px;
  }

  .visit-history-content-wrap {
    padding: 15px;
  }

  .reason-visit-wrap {
    padding: 15px;
  }

  .dashBcontbody .patient-detail h3 {
    font-size: 18px;
  }

  .patient-detail .h4,
  .patient-detail h4 {
    font-size: 15px;
  }

  .btn.transaparent-btn {
    font-size: 10px;
  }

  .btn.transaparent-btn img {
    width: 25%;
  }

  .patient-profile-img-wrap img {
    width: 90px;
    height: 90px;
    margin-bottom: 12px;
  }

  .patient-header-content-wrap h3 {
    font-size: 18px;
  }

  .patient-header-content-wrap h6 {
    font-size: 14px;
  }

  .patient-header-wrap .custom_btn {
    padding: 8px 18px;
    font-size: 10px;
  }

  .patient-header-wrap {
    padding-bottom: 25px;
  }

  .patient-bottom-wrap {
    padding-top: 25px;
  }

  .patient-summary-wrap:not(:last-child) {
    margin-right: 8px;
  }

  .reason-visit-wrap p {
    font-size: 11px;
  }

  .patient-detail-card h4 {
    font-size: 14px;
  }

  .avatar-img-wrap {
    margin-right: 6px;
  }

  .opportunities-items .p {
    font-size: 9px;
  }

  .documents-link-wrap a {
    font-size: 12px;
    padding-left: 15px;
  }

  .amount-input-wrap .form-control {
    padding: 10px 90px 10px 10px;
    font-size: 10px;
  }

  .amount-input-wrap .custom_btn {
    padding: 6px 11px;
    font-size: 8px;
  }

  .avatar-img-wrap img {
    width: 30px;
    height: 30px;
  }

  .opportunities-items .custom_btn {
    font-size: 7px;
    padding: 5px 8px;
  }

  .patient-detail-card.patient-detail-summary-wrap::before {
    left: 0;
  }

  .custom-accordion-wrapper .accordion-button::after {
    left: 150px;
  }
}
