header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 8px 34px 8px 9px !important;
  border-radius: 90px;
  /* background: #ececf8; */
  margin-top: -10px !important;
}
.header-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 133%;
  margin-right: 20px;
}
.navBackBtn {
  border: 1px solid var(--green-900);
  border-radius: 12px;
  padding: 12px;
  width: 48px;
  height: 48px;
  background: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mytitle {
  color: var(--stratos);
  background: none;
  border: none;
  font-size: 16px;
}

.mytaskLink {
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 12px 24px;
  height: 48px;
  color: var(--green-800);
  background-color: var(--white);
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  border-color: var(--green-900);
}
.appointmentsLink {
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 12px 24px;
  /* width: 152px; */
  height: 48px;
  background: var(--green-800);
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #ffffff;
}
.header-links-wrap a:first-child {
  border: unset;
}

.header-links-wrap a:hover {
  text-decoration: underline;
}

.header-links-wrap a {
  color: #060030;
  font-size: 14px;
  letter-spacing: -0.56px;
  padding-left: 10px;
  padding-right: 5px;
  font-weight: 600;
  line-height: normal;
  border-left: 1px solid #dbdbec;
  border-radius: 0px;
}



.header-links-wrap a:hover,
.header-links-wrap a:active,
.header-links-wrap a:focus {
  color: var(--green-800);
}

.nav__toggler .line {
  width: 25px;
  height: 2px;
  background-color: #000;
  display: block;
  margin: 5px 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.nav__toggler .line:nth-child(2) {
  width: 18px;
  margin-right: auto;
}
html.show-menu .line:nth-child(1) {
  -webkit-transform: translateY(13px) rotate(45deg);
  -ms-transform: translateY(13px) rotate(45deg);
  transform: translateY(8px) rotate(45deg);
}
html.show-menu .line:nth-child(2) {
  opacity: 0;
}
html.show-menu .line:nth-child(3) {
  -webkit-transform: translateY(-13px) rotate(-45deg);
  -ms-transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg);
}
.search_bar.search-wrapper {
  position: relative;
}
.search_bar.search-wrapper .material-input {
  max-width: 450px;
  min-width: 250px;
}
.search_bar.search-wrapper .MuiOutlinedInput-root {
  padding: 0px;
  height: 48px;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 500;
  background: #fff;
  outline: none;
  /* border: none !important; */
  border: 1px solid var(--gray-400);
  box-shadow: none;
  padding-left: 30px;
  color: #060030;
}
.search_bar.search-wrapper .MuiAutocomplete-input {
  padding: 10px 0px 10px 40px;
  height: auto;
  /* border: none; */
}
.search_bar.search-wrapper fieldset {
  /* border: none; */
}
/* .search_bar.search-wrapper .css-1h51icj-MuiAutocomplete-root:focus {
  box-shadow: 0 0 0 0.2rem rgb(5 200 130 / 25%) !important;
} */
/* Responsive Design */
@media only screen and (max-width: 1439px) {
  .search_bar .form-control {
    height: 48px;
  }
}

@media only screen and (max-width: 991px) {
  header {
    padding: 8px 8px 8px 9px !important;
  }

  .headarstyle .nav__item a:hover {
    color: #fff;
  }

  .nav__menu {
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    width: 70%;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    background-color: rgba(255, 255, 255, 1);
    overflow-y: auto;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    padding: 15px 20px;
    z-index: 99;
    -webkit-box-shadow: -10px 10px 20px 0 rgba(0, 0, 0, 0.2);
    box-shadow: -10px 10px 20px 0 rgba(0, 0, 0, 0.2);
  }

  .diflexmenu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 0px;
    background: var(--green-800);
    border-radius: 25px;
    padding: 30px;
    height: auto;
  }

  html.show-menu .nav__menu {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
  }

  html.show-menu .nav__item.itemphon {
    display: block !important;
  }
  /* .nav__item:not(:last-child) {
    margin-bottom: 15px;
  } */
  .sidebarbg {
    display: none;
  }

  html.show-menu .btn_warp {
    padding: 0px;
  }
  .diflexmenu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 0px;
    background: var(--green-800);
    border-radius: 25px;
    padding: 30px;
    height: auto;
    /* height: 100%; */
    /* max-height: 800px;
      overflow-y: auto; */
  }
  .navicon {
    width: 35px;
    padding: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 10px;
  }
  header {
    background: var(--gray-50);
    margin: -15px !important;
    border-radius: 0px;
    width: auto;
    padding: 15px;
  }

  .search_bar {
    margin-right: 10px;
  }
  .search_bar .form-control {
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    height: 52px;
    width: 52px;
    font-size: 0px;
    -webkit-box-shadow: 0px 1px 3px 0px #7885b226;
    box-shadow: 0px 1px 3px 0px #7885b226;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0px;
    background: url("../../assets/images/srcicon.svg") no-repeat;
    background-position: center center;
    background-color: #fff;
  }
  /* .search_bar img {
    display: none;
  } */
  .search_bar .form-control:hover,
  .search_bar .form-control:focus {
    width: 160px;
    font-size: 14px;
    padding: 0px 15px;
    background-position: 130px center;
  }
  header .profile-shortname {
    margin-right: 0px;
  }
  .nav__toggler {
    z-index: 100;
    margin-right: 20px;
  }

  .search_bar.search-wrapper .MuiOutlinedInput-root {
    border: 1px solid #eee !important;
    width: 160px;
  }
  /* .search_bar.search-wrapper
    .MuiOutlinedInput-root
    input::-webkit-input-placeholder {
    color: transparent;
  } */
  .navflex .navicon.sidebarImg img {
    filter: invert(99%) sepia(7%) saturate(163%) hue-rotate(259deg)
      brightness(112%) contrast(100%);
  }
}

@media only screen and (max-width: 767px) {
  header .profile-shortname {
    width: 40px;
    height: 40px;
  }
  .search_bar .form-control {
    height: 40px;
    width: 40px;
  }
  .custom-dropdown span {
    font-size: 17px;
  }
  .header-logo {
    width: 46%;
  }
  .nav__menu {
    width: 100%;
  }
}
@media only screen and (max-width: 414px) {
  .search_bar.search-wrapper .MuiOutlinedInput-root {
    width: 140px;
  }
}
