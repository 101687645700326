.emptyState {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyState > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 48px 24px;
  text-align: left;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.emptyStateTitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 8px;
}

.emptyStateText {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-width: 300px;
}
