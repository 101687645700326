.addPostNatalConsultationFormWrapper {
  border-radius: 12px;
  border: 1px solid var(--gray-200);
  background: var(--gray-50);
  padding: 24px;
  width: calc(100% + 50px);
  margin-bottom: -1px;
  margin-top: 16px;
}

.addPregnancyForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 0;
}

.saveButton:hover {
  text-decoration: underline;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 56px;
}

.buttonRow .addConsultationButton {
  background-color: var(--green-800);
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 180px;
  height: 48px;
}

.pregnancyFormLabel {
  color: var(--gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}

.inputGroupGrid4Columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
}

.inputGroupGrid3Columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}

.inputGroupGrid2Columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.pregnancyFormSectionTitle {
  color: var(--gray-950);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  padding-top: 24px;
  border-top: 1px solid var(--gray-200);
}

.pregnancyFormSectionTitleWithoutBorder {
  color: var(--gray-950);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

.addPregnancyForm input,
.addPregnancyForm select,
.addPregnancyForm textarea {
  height: 48px;
  border-radius: 12px !important;
  color: var(--gray-500);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px;
}

.attachemntButtons {
  display: flex;
  gap: 8px;
  position: absolute;
  bottom: 78px;
  left: 24px;
}

.cancelButton {
  background-color: white;
  color: var(--green-800);
  border: 1px solid var(--green-800);
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  height: 48px;
}

.topBorder {
  padding-top: 24px;
  border-top: 1px solid var(--gray-200);
}

.addPregnancyFormTooltip {
  position: relative;
  margin-top: -4px;
}

.cmInput {
  position: relative;
  height: 48px;
}

.cmInput input {
  position: relative;
  padding-right: 40px;
}

.cmInput::before {
  display: block;
  position: absolute;
  content: "cm";
  top: 0;
  right: 0;
  height: 48px;
  width: 24px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray-500);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-right: 16px;
}

.gramsInput {
  position: relative;
  height: 48px;
}

.gramsInput input {
  position: relative;
  padding-right: 56px;
}

.gramsInput::before {
  display: block;
  position: absolute;
  content: "grams";
  top: 0;
  right: 0;
  height: 48px;
  width: 44px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray-500);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-right: 16px;
}

.addPregnancyDatePickerWrapper > div > div {
  height: 48px;
  border: 1px solid var(--gray-400);
  border-radius: 12px;
  font-family: "nunito";
  font-size: 14px;
  background-color: var(--white);
}

.addPregnancyDatePickerWrapper fieldset {
  border: none;
}

.pregnancyFormCheckboxLabel {
  color: var(--gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  align-items: center;
  display: flex;
  gap: 8px;
}

.checkboxSectionLegend {
  color: var(--Gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2px;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}

.pregnancyFormCheckboxLabelWithTopMargin {
  margin-top: 6px;
}
