.pregnancyAnteNatalConsultationDoctorSection {
  padding: 12px 0;
  margin-bottom: 12px;
  border-bottom: 1px solid var(--gray-200);
}

.pregnancyAnteNatalConsultationDoctorSection h3 {
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.pregnancyAnteNatalConsultationDoctorSection p {
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.pregnancyAnteNatalConsultationExamSection {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid var(--gray-200);
}

.pregnancyAnteNatalConsultationExamSection h3 {
  color: var(--gray-950);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 12px;
}

.pregnancyAnteNatalConsultationExamSection > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.pregnancyAnteNatalConsultationExamSectionItem h4 {
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.pregnancyAnteNatalConsultationExamSectionItem p {
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.pregnancyAnteNatalBottomSection {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
