.tableContainer {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid var(--gray-200);
}
.table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
}
.thead {
  width: 100%;
  padding: 12px;
  background: var(--gray-100);
}
.tdName {
  width: 250px;
}
.checkIcon {
  position: absolute;
  left: -50px;
  bottom: 24px;
}
tr {
  width: 100%;
  transition: all 0.3s ease;
}
th,
td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--gray-200);
}
tbody tr:last-child td {
  border-bottom: none;
}
table tbody tr:hover {
  background-color: var(--gray-50);
}
tr:hover .hiddenButtons {
  visibility: visible;
}
th {
  font-weight: 700;
  font-size: 14px;
  line-height: 143%;
  color: var(--gray-950);
}
td {
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: var(--gray-950);
}
.descr {
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.actions {
  display: flex;
  justify-content: flex-end;
}

.addButton,
.deleteButton {
  background: none;
  border: 1px solid #ddd;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.addButton {
  padding: 7px 21px;
  border: 1.17px solid var(--green-800);
  border-radius: 7px;
  color: var(--green-800);
  font-weight: 600;
  cursor: pointer;
}
.stopButton {
  border: none;
  background: transparent;
}
.deleteButton {
  border: none;
}
.deleteIcon {
  width: 20px;
  height: 20px;
}
.hiddenButtons {
  visibility: hidden;
}
.tooltip {
  background: var(--gray-950);
  border-radius: 4px;
  padding: 8px 10px;
  width: 296px;
}
