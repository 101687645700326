.pregnancyAnteNatalView {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--gray-200);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
  width: 100%;
}

.pregnancyAnteNatalViewConsultationMode {
  padding-bottom: 0;
}

.pregnancyAnteNatalViewTitleWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.pregnancyAnteNatalViewTitle {
  color: var(--gray-950);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.pregnancyAnteNatalStartConsultationButton {
  background: var(--green-800);
  border: 1px solid var(--green-900);
  border-radius: 12px;
  padding: 12px 24px;
  width: 180px;
  height: 48px;
  color: var(--white);
}

.pregnancyAnteNatalConsultationWrapper {
  display: grid;
  grid-template-columns: 64px 1fr 18px;
  gap: 24px;
  width: 100%;
  padding: 12px 0;
  margin-bottom: 12px;
  border-bottom: 1px solid var(--gray-200);
}

.pregnancyAnteNatalConsultationWrapper:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.pregnancyAnteNatalConsultationDateBox {
  display: flex;
  height: 66px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--green-950);
  background: var(--green-50);
}

.pregnancyAnteNatalConsultationDateBox span:first-child {
  color: var(--green-950);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.pregnancyAnteNatalConsultationDateBox span:nth-child(2) {
  color: var(--green-950);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.showMoreButtonWrapper {
  position: relative;
  width: 18px;
  height: 18px;
}

.showMoreButton {
  position: absolute;
  top: -11px;
  left: -11px;

  border: none;
  background-color: transparent;
  width: 40px;
  height: 40px;
  padding: 0;
  transform-origin: 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.showMoreButtonExpanded {
  transform: rotate(180deg);
}

.pregnancyAnteNatalConsultationContentWrapper {
  width: 100%;
  overflow: hidden;
}

.pregnancyAnteNatalStartConsultationsWarpper {
  width: 100%;
  padding-right: 12px;
  padding-top: 26px;
}

.pregnancyAnteNatalConsultationTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pregnancyAnteNatalConsultationTitleWrapper h2 {
  color: var(--gray-950);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.pregnancyAnteNatalConsultationTitleWrapper p {
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.pregnancyAnteNatalConsultationText {
  color: var(--Gray-950, #26252c);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.pregnancyAnteNatalConsultationTextExpanded {
  white-space: wrap;
  overflow: visible;
}
