.cardWrapper {
  position: relative;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--gray-200);
  background: #fff;
}
/* .headerTableBackground {
  position: absolute;
  top: 84px;
  left: 0;
  width: 100%;
  height: 41px;
  background: #ececf8;
} */
