.addPregnancyForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 0;
}

.addPregnancyForm input,
.addPregnancyForm select,
.addPregnancyForm textarea {
  height: 48px;
  border-radius: 12px !important;
  color: var(--gray-500, #717186);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px;
}

.saveButton:hover {
  text-decoration: underline;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  position: relative;
  padding-top: 24px;
}

.buttonRow::after {
  content: "";
  position: absolute;
  top: 0;
  height: 1px;
  background-color: var(--gray-400);
  left: -24px;
  right: -24px;
}

.buttonRow .addPregnancyButton {
  background-color: var(--green-800);
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}

.buttonRow .cancelButton {
  background-color: white;
  color: var(--green-800);
  border: 1px solid var(--green-800);
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  height: 44px;
}

.pregnancyFormLabel {
  color: var(--Gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}

.addPregnancyFormTooltip {
  position: relative;
  margin-top: -4px;
}

.pregnancyFormCheckboxLabel {
  color: var(--gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  align-items: center;
  display: flex;
  gap: 8px;
}

.checkboxSectionLegend {
  color: var(--Gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2px;
}

.inputGroupGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.bottomSectionInputGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}

.medsCheckboxGroup {
  padding: 24px 0;
}

.addPregnancyForm input {
  height: 48px;
}

.addPregnancyDatePickerWrapper > div > div {
  height: 48px;
  border: 1px solid var(--gray-400);
  border-radius: 12px;
  font-family: "nunito";
  font-size: 14px;
}

.addPregnancyDatePickerWrapper fieldset {
  border: none;
}

.mlInput {
  position: relative;
  height: 48px;
}

.mlInput input {
  position: relative;
  padding-right: 40px;
}

.mlInput::before {
  display: block;
  position: absolute;
  content: "ml";
  top: 0;
  right: 0;
  height: 48px;
  width: 24px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray-500);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-right: 16px;
}
