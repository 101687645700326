.wrapper {
  padding: 2rem 3rem;
}

.label {
  font-size: 1rem;
  font-weight: 500;
}

.editor {
  margin-top: 1rem;
  height: 500px;
}

.ql-editor {
  min-height: 400px;
  overflow: auto;
  color: var(--gray-500, #717186);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px;
}
