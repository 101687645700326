.carousel-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}
.carousel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding: 0px 0px 20px;
}
.carousel-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}
.carousel-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 500;
  font-size: 17px;
  width: 62px;
  height: 85px;
  cursor: pointer;
  margin-right: 12px;
  background-color: transparent;
  border: 1px solid var(--gray-400);
  border-radius: 12px;
  color: #8d8da3;
  text-align: center;
}
.carousel-item.selected {
  background: #5d35e9;
  -webkit-box-shadow: 0px 2px 6px rgba(120, 133, 179, 0.15);
  box-shadow: 0px 2px 6px rgba(120, 133, 179, 0.15);
  color: #fff;
}
.carousel-item.today {
  background: #5d35e9;
  -webkit-box-shadow: 0px 2px 6px rgba(120, 133, 179, 0.15);
  box-shadow: 0px 2px 6px rgba(120, 133, 179, 0.15);
  color: #fff;
}
.carousel-item.today::before,
.carousel-item.selected::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0px 6px;
  border-color: #5d35e9 transparent transparent transparent;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -6px;
  z-index: 1;
}
.carousel-button {
  border: none;
  background-color: transparent;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  position: absolute;
  top: -30px;
  right: 0;
  color: #000;
}
.carousel-prev {
  margin-right: 30px;
}
.carousel-next {
  margin-left: 10px;
}
.carousel-button:not(:disabled) {
  color: #5d35e9;
}
.timelist_sect {
  border: 1px solid var(--gray-400);
  border-radius: 12px;
  height: 477px;
  overflow-y: scroll;
  padding: 26px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 18px 15px;
}
.timelist_sect li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 48.2%;
  flex: 0 0 48.2%;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 2px solid var(--green-800);
  border-radius: 10px;
  color: var(--green-800);
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
.timelist_sect li.active,
.timelist_sect li:hover {
  color: #fff;
  background: var(--green-800);
}
.manthyearsect button {
  display: none;
}
.manthyearsect select {
  border: 0px;
  margin-right: 25px;
  font-weight: 400;
  font-size: 20px;
  color: #060030;
}
.manthyearsect {
  margin-bottom: 20px;
}
.manthyearsect h3 {
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
  color: #060030;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .carousel-items {
    gap: 5px;
  }
  .carousel-item {
    margin-right: 0;
    width: auto;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
  }
  .timelist_sect {
    gap: 10px;
  }
  .timelist_sect li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 46%;
    flex: 0 0 46%;
  }
  .manthyearsect h3 {
    font-size: 24px;
  }
  .carousel-button {
    top: -45px;
  }
  .manthyearsect select {
    font-size: 16px;
  }
}
