.patientDetailCard {
  position: relative;
  width: 100%;

  overflow: hidden;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--gray-200);
  background: #fff;
  text-wrap: normal;
}
.leftHalfRoundImg {
  position: absolute;
  top: 85px;
  left: -23px;
}
.bottomHalfRoundImg {
  position: absolute;
  bottom: 0;
  left: 60px;
}
.rightHalfRoundImg {
  position: absolute;
  bottom: 0;
  right: 0;
}
.patientName {
  display: flex;
  gap: 5px;
  align-items: center;
}
.patientAge {
  font-size: 16px;
  font-weight: 400;
  color: #858585;
}
.patientHeaderWrap {
  position: relative;
  margin-bottom: 1rem;
  border-bottom: 1.75px solid #ececfa;
}
.editBtn {
  border: none;
}
.actionBtnsWrapper {
  position: absolute;
  right: 0;
  top: 90px;
  display: flex;
  gap: 10px;
}
.actionBtn {
  border: 1px solid var(--green-900);
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}
.dFlex {
  display: flex;
}

.flexSmNoWrap {
  flex-wrap: nowrap;
}

.flexWrap {
  flex-wrap: wrap;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyContentSmStart {
  justify-content: flex-start;
}

.patientProfileImgWrap {
  margin-right: 30px;
}

.userProfileImage {
  background-color: #f0f0f0;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  font-size: 1.5rem;
  color: #333;
}
.patientNameSurname {
  font-size: 23px;
  font-weight: 600;
}

.patientHeaderContentWrap {
  align-self: flex-start;
  flex-grow: 1;
  max-width: 310px;
}

.patientHeaderContentWrap p {
  margin-bottom: 4px;
}

.patientBottomWrap {
  display: flex;
  flex-wrap: wrap;
}
.patientLeftDescription {
  margin-right: 50px;
}

.patientLeftDescription,
.patientRightDescription {
  flex: 1;
  width: 45%;
}

.patientBlockWrap {
  margin-bottom: 1rem;
}

.textManatee {
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: var(--gray-600);
  margin-bottom: 5.5px;
}

.fw500 {
  font-weight: 500;
}

.h6 {
  font-size: 1rem;
  margin: 0;
}

.textStratos {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--gray-950);
  text-wrap: normal;
}

.textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flexShrink0 {
  flex-shrink: 0;
}

.btnWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.divider {
  width: 1.75px;
  height: 134px;
  background-color: #ececfa;
  margin-right: 20px;
  margin-left: 10px;
  align-self: center;
}
.customBtn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.customBtn:hover {
  background-color: #0056b3;
}
@media screen and (max-width: 1440px) {
  .patientDetailCard {
    width: 100%;
  }
  .userProfileImage {
    width: 80px;
    height: 80px;
  }
  .patientHeaderWrap {
    padding-bottom: 8px;
  }
  .actionBtnsWrapper {
    top: 100px;
    right: 50%;
    transform: translateX(50%);
  }
}
