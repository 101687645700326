.reportTableWrapper {
  width: 100%;
  position: relative;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--gray-200);
  background: #fff;
}

.reportTablePaper {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.paginationWrapper {
  display: flex;
  justify-content: center;
  padding: 20px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background-color: transparent;
}

.rowsPerPageWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.reportTableWrapper h3 {
  color: var(--black);
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
}

.reportTable {
  border-radius: 12px;
  border: 1px solid var(--gray-200);
  background-color: white;
}

.reportTableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reportTableHeaderButtons {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.reportTableHeaderButton {
  display: flex;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: none;
  border: 1px solid var(--green-800);
  color: var(--green-800);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.reportTableItem {
  border-bottom: 1px solid var(--gray-200);
  display: grid;
  gap: 8px;
  padding: 16px;
  grid-template-columns: 18px 135px 1fr 120px 70px 1fr 90px 100px 70px 85px 26px;
  color: var(--gray-950);
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 1400px) {
  .reportTableItem,
  .reportTableHeader {
    grid-template-columns: 18px 1fr 1fr 1fr 1fr 1fr 100px 105px 70px 85px 26px;
  }
}

.reportTableItem:last-child {
  border-bottom: none;
}

.checkboxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchWrapper {
  border-right: 1px solid var(--gray-200);
  max-width: 330px;
}

@media (max-width: 991px) {
  .searchWrapper img {
    display: none;
  }
}

.reportTableTopBarWrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-200);
}

.sortByButton {
  color: var(--green-900);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: none;
  background-color: transparent;
  height: 52px;
  border-left: 1px solid var(--gray-200);
}

.sortByButtonMenuOption p {
  display: flex;
  gap: 8px;
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sortByButtonMenuOptionActive {
  background-color: var(--gray-100) !important;
}

.reportTableSelectedItemsSection {
  border-bottom: 1px solid var(--gray-200);
  display: grid;
  gap: 16px;
  align-items: center;
  padding: 16px;
  grid-template-columns: 18px 1fr 80px;
  color: var(--gray-950);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: var(--gray-950);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background: var(--gray-50);
}

.reportTableSelectedItemsSection button {
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--green-900);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.menuButton {
  border: none;
  background-color: transparent;
  padding: 0;
  width: 26px;
  height: 26px;
  border-radius: 2px;
}

.menuButton:hover,
.menuButton:active {
  border: 1px solid var(--gray-300);
}

.menuItemWrapper {
  padding: 0;
}
.menuItem {
  padding: 0 !important;
}

.menuItem a,
.menuItem button {
  font-family: Nunito;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: none;
  background-color: transparent;
  width: 100%;
  padding: 12px 20px;
}

.reportNoReportsWarning {
  color: var(--black);
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin: 64px 24px;
  text-align: center;
}

.tableContainer {
  overflow-x: auto;
}

.tableContainer table {
  border-spacing: 0;
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--gray-200);
}

.tableContainer th,
.tableContainer td {
  margin: 0;
  padding: 16px;
  border-bottom: 1px solid var(--gray-200);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableContainer th {
  position: relative;
  background: var(--gray-100);
  color: var(--gray-950);
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  user-select: none;
}

.tableContainer td {
  color: var(--gray-950);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.resizer {
  position: absolute;
  right: 6px;
  top: 0;
  height: 100%;
  width: 1px;
  background: rgba(0, 0, 0, 0.1);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 1;
}

.resizer:hover .resizerIcon,
.resizer.isResizing .resizerIcon {
  border-color: var(--gray-300);
}

.resizerIcon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 999;
  height: 16px;
  width: 16px;
  transform: translate(45%, -50%);
  color: #666;
  background: var(--gray-100);
  border-radius: 3px;
  border: 1px solid var(--gray-200);
  width: 12px;
  height: 18px;
  cursor: col-resize;
}

.tableHeaderCell:hover .resizer {
  opacity: 1;
}

.resizer.isResizing,
.resizer:hover {
  cursor: col-resize;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.tableContainer tr:last-child td {
  border-bottom: none;
}

.sortByButton {
  color: var(--green-900);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: none;
  background-color: transparent;
  height: 52px;
  border-left: 1px solid var(--gray-200);
}

.sortByButtonMenuOption p {
  display: flex;
  gap: 8px;
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sortByButtonMenuOptionActive {
  background-color: var(--gray-100);
}

.menuButton {
  border: none;
  background-color: transparent;
  padding: 0;
  width: 26px;
  height: 26px;
  border-radius: 2px;
}

.menuButton:hover,
.menuButton:active {
  border: 1px solid var(--gray-300);
}

.menuItemWrapper {
  padding: 0;
}
.menuItem {
  padding: 0 !important;
}

.menuItem a,
.menuItem button,
.menuItem p {
  font-family: Nunito;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: none;
  background-color: transparent;
  width: 100%;
  padding: 12px 20px;
}

.reportNoReportsWarning {
  color: var(--black);
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin: 64px 24px;
  text-align: center;
}

.tableHeaderCell {
  position: relative;
  padding: 16px;
  font-weight: 600;
  user-select: none;
  overflow: visible;
}

.tableHeaderCell:hover .resizer {
  opacity: 1;
}

/* .tableHeaderRow {
  background: var(--gray-100);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
} */
