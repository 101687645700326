.inputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  p {
    font-size: 16px;
  }
}
.saveButton {
  background-color: var(--green-800);
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}
.rowTitle {
  align-self: flex-start;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--gray-950);
  min-width: 300px;
}
.input {
  border: 1px solid var(--gray-400);
  border-radius: 12px;
  padding: 12px 20px;
  height: 48px;
  width: 100%;
}
