.addAnteNatalConsultationFormWrapper {
  border-radius: 12px;
  border: 1px solid var(--gray-200);
  background: var(--gray-50);
  padding: 24px;
  width: calc(100% + 50px);
  margin-bottom: -1px;
  margin-top: 16px;
}

.addPregnancyForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 0;
}

.saveButton:hover {
  text-decoration: underline;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
}

.buttonRow .addConsultationButton {
  background-color: var(--green-800);
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 180px;
  height: 48px;
}

.pregnancyFormLabel {
  color: var(--Gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}

.inputGroupGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}

.inputGroupGrid > div > label {
  max-width: 238px;
}

.pregnancyFormSectionTitle {
  color: var(--gray-950);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  padding-top: 24px;
  border-top: 1px solid var(--gray-200);
}

.addPregnancyForm input,
.addPregnancyForm select,
.addPregnancyForm textarea {
  height: 48px;
  border-radius: 12px !important;
  color: var(--gray-500, #717186);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px;
}

.cmInput {
  position: relative;
  height: 48px;
}

.cmInput input {
  position: relative;
  padding-right: 40px;
}

.cmInput::before {
  display: block;
  position: absolute;
  content: "cm";
  top: 0;
  right: 0;
  height: 48px;
  width: 24px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray-500, #717186);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-right: 16px;
}

.attachemntButtons {
  padding-top: 24px;
  border-top: 1px solid var(--gray-200);
  display: flex;
  gap: 8px;
}

.cancelButton {
  background-color: white;
  color: var(--green-800);
  border: 1px solid var(--green-800);
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  height: 48px;
}
