.vaccineDetailsWrapper {
  background: var(--Gray-100, #eeeef0);
  padding: 24px;
  gap: 32px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-top: 16px;
}

.vaccineDetailsButtonRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.vaccineDetailsButtonRow div {
  display: flex;
  gap: 28px;
}

.vaccineDetailsButtonRow button {
  border: none;
  background-color: transparent;
  color: var(--green-900);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 4px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.vaccineDetailsButtonRow .deleteButton {
  color: var(--alert, #b70000);
}

.vaccineDetailsInfoGrid {
  border-radius: 12px;
  padding: 24px 20px;
  gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: var(--White, #fdfdfd);
}

.vaccineDetailsInfoGrid h4 {
  color: var(--gray-950);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.vaccineDetailsInfoGrid p {
  color: var(--gray-950);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
