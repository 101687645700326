.addButtonWrapper {
    margin-bottom: 10px;
    width: 100%;
}
.topWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

}
.titleWrapper {
    display: flex;
    align-items: center;
    gap: 11px;
}
.title {
    font-weight: 600;
    font-size: 20px !important;
    color: var(--black);
}
.button {
    background: none;
    border: none;
    cursor: pointer;
}
.icon {
    width: 16px;
    height: 16px;
}
.addButton {
    border: none;
}