.pregnancyAnteNatalConsultationDoctorSection {
  padding: 8px 0;
}

.pregnancyPostNatalConsultationLabel {
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.pregnancyPostNatalConsultationContent {
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.pregnancyPostNatalConsultationSectionTitle {
  color: var(--gray-950);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  padding: 12px 0;
  margin-top: 12px;
  border-top: 1px solid var(--gray-200);
}

.pregnancyPostNatalConsultationTwoColumnSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-bottom: 12px;
}

.pregnancyPostNatalConsultationSection {
  margin-bottom: 12px;
}

.pregnancyPostNatalConsultationFeedingLabel {
  display: flex;
  gap: 8px;
  color: var(--gray-950);
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  align-items: center;
}
