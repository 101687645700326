.container {
  position: absolute;
  background: #e7eefa;
  color: black;
  width: 100%;
  height: 100%;
  left: 0;
  top: 54px;
  min-height: 320px;
  padding: 5px;
  z-index: 1000;
  border-radius: 5px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 15px;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.name {
  font-size: 16px;
  color: #000;
}

.ageGender {
  font-size: 12px;
  color: #3e3e46;
  text-align: left;
}

.userProfileImage {
  width: 34px;
  height: 34px;
  border-radius: 10px;
  -o-object-fit: cover;
  object-fit: cover;
  font-size: 16px;
  border-radius: 50%;
  background-color: brown;
  color: #ffffff;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.userProfileImage img {
  border-radius: 50%;
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}

.icons {
  display: flex;
  gap: 10px;
}

.detailsBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detailsBox li {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.detailsBox li h6 {
  color: #8d8da3;
  font-size: 12px;
  font-weight: 500;
}

.detailsBox li p {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.deleteButtonsContainer {
  display: flex;
  gap: 10px;
}
