.tableWarpper {
  width: 100%;
  position: relative;
  background-color: #ffffff;
  background: #fff;
}

.tableWarpper h3 {
  color: var(--black);
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
}

.table {
  border-radius: 12px;
  border: 1px solid var(--gray-200);
}

.emptyState {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
  text-align: center;
  background: #f8f9fa;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.emptyStateTitle {
  font-size: 16px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 8px;
}

.emptyStateText {
  font-size: 14px;
  color: #717186;
  max-width: 300px;
}

.loadingSpinner {
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
  color: #717186;
}

.tableTitle {
  color: var(--black);
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 32px;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tableHeader {
  display: grid;
  gap: 8px;
  padding: 16px;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 26px;
  background: var(--gray-100);
  color: var(--gray-950);
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  border-bottom: 1px solid var(--gray-200);
}

.tableItem {
  border-bottom: 1px solid var(--gray-200);
  display: grid;
  gap: 8px;
  padding: 16px;
  grid-template-columns: 1fr 1fr 1fr 26px;
  color: var(--gray-950);
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 1400px) {
  .tableItem,
  .tableHeader {
    grid-template-columns: 1fr 1fr 1fr 26px;
  }
}

.tableItem:last-child {
  border-bottom: none;
}

.checkboxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchWrapper {
  border-right: 1px solid var(--gray-200);
  max-width: 330px;
}

@media (max-width: 991px) {
  .searchWrapper img {
    display: none;
  }
}

.tableTopBarWrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-200);
}

.sortByButton {
  color: var(--green-900);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: none;
  background-color: transparent;
  height: 52px;
  border-left: 1px solid var(--gray-200);
}

.sortByButtonMenuOption p {
  display: flex;
  gap: 8px;
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sortByButtonMenuOptionActive {
  background-color: var(--gray-100) !important;
}

.tableSelectedItemsSection {
  border-bottom: 1px solid var(--gray-200);
  display: grid;
  gap: 16px;
  align-items: center;
  padding: 16px;
  grid-template-columns: 18px 1fr 80px;
  color: var(--gray-950);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: var(--gray-950);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background: var(--gray-50);
}

.tableSelectedItemsSection button {
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--green-900);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.menuButton {
  border: none;
  background-color: transparent;
  padding: 0;
  width: 26px;
  height: 26px;
  border-radius: 2px;
}

.menuButton:hover,
.menuButton:active {
  border: 1px solid var(--gray-300);
}

.menuItemWrapper {
  padding: 0;
}
.menuItem {
  padding: 0 !important;
}

.menuItem a,
.menuItem button,
.menuItem p {
  font-family: Nunito;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: none;
  background-color: transparent;
  width: 100%;
  padding: 12px 20px;
}

.reportNoReportsWarning {
  color: var(--black);
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin: 64px 24px;
  text-align: center;
}
