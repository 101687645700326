.commsMenu {
    position: relative;
    width: 100%;

    overflow: hidden;
    background-color: #ffffff;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid var(--gray-200);
    background: #fff;
    text-wrap: wrap;
}

.menuIcon {
    width: 1rem;
    position: relative;
    height: 1rem;
}
.menuText {
    position: relative;
    line-height: 1.5rem;
}

.menuItem:hover {
    align-self: stretch;
    border-radius: 12px;
    text-decoration: underline;
    color: #002f22;
    cursor: pointer;
}
.menuItem.active {
    align-self: stretch;
    border-radius: 12px;
    background-color: #e7efeb;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75rem 1.5rem 0.75rem 1rem;
    gap: 0.625rem;
    color: #002f22;
}

.menuItemChecked {
    align-self: stretch;
    border-radius: 12px;
    background-color: #e7efeb;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75rem 1.5rem 0.75rem 1rem;
    gap: 0.625rem;
    color: #002f22;
}
.menuItem {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75rem 1.5rem 0.75rem 1rem;
    gap: 0.625rem;
}
