.pregnancyGeneralInformationView {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--gray-200);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
  width: 100%;
}

.pregnancyFormCheckboxLabel {
  color: var(--gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  align-items: center;
  display: flex;
  gap: 8px;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.generalInfoMainSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--gray-200);
  margin-top: 12px;
}

.bottomSection {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  width: 100%;
  padding-bottom: 16px;
}

.generalInfoMainSection h3,
.bottomSection h3,
.generalInfoMainSection legend {
  color: var(--gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 0;
}

.generalInfoMainSection p,
.bottomSection p,
.generalInfoMainSection label {
  color: var(--gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.generalInfoMainSection label {
  font-size: 18px;
  font-weight: 600;
}

.generalInfoTitle {
  color: var(--gray-950);
  text-align: start;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  line-height: 32px;
}

.generalInfoTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
}

.generalInfoTitleWrapper button {
  color: var(--green-900);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  background-color: transparent;
  border: none;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 32px;
}
