.subTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 156%;
  color: #000;
  margin-bottom: 24px;
}
.label {
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--gray-950);
  min-width: 300px;
}

.formTopContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 10px;
  margin: 20px;
}
