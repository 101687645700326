.button {
  color: white;
  background-color: var(--green-800);
  border: 1px solid var(--green-800);
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  height: 48px;
  font-weight: 600;
}

.taskInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-top: 12px;
}

.taskInfo label {
  font-weight: 600;
}

.taskInfo > div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.taskInfo button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: 400;
  display: inline;
  font-size: 16px;
  width: auto;
  text-align: left;
  padding: 0;
  margin: 0;
}

.taskInfo button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 16px;
  border-top: 1px solid var(--gray-200);
  padding-top: 16px;
}

.taskInfoSpanCol2 {
  grid-column: span 2;
}
