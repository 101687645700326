@media only screen and (max-width: 1599px) {
  .sidebarbox .menulist img {
    width: 50%;
  }
  .sidebarbox .menulist {
    width: 45px;
    height: 45px;
  }
  .sidebarbox {
    width: 66px;
    padding: 25px 0;
  }
  .sidebarbox .menulist:not(:last-child) {
    margin-bottom: 10px;
  }
  .sidebarmenu {
    height: 50px;
  }
  .sidebarbox .menulist:hover .sidebarmenu {
    left: 60px;
    padding: 0px 20px 0px 20px;
    font-size: 14px;
  }
}

@media (max-width: 1439px) {
  .nav.dashBnav .navbar-brand {
    width: 60px;
  }
}
@media (max-width: 1399px) {
  .progresssteapes li h6::after {
    width: 12px;
    right: -6px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebarbox .menulist {
    width: 35px;
    height: 35px;
  }
  .sidebarbox .menulist:not(:last-child) {
    margin-bottom: 5px;
  }
  .sidebarbox {
    padding: 18px 0px;
  }
  .sidebarbox .menulist:hover .sidebarmenu {
    font-size: 12px;
    height: 35px;
  }
  .sidebarbox .menulist.active::after {
    width: 8px;
    height: 35px;
  }
  .nav.dashBnav .navbar-brand img {
    width: 50px;
  }
}

@media (max-width: 1297px) {
  .signup_threebox.patientlist ul.patientlist li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12%;
    flex: 0 0 12%;
  }
  .signup_threebox.patientlist ul.patientlist li:nth-child(3) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 17%;
    flex: 0 0 17%;
  }
  .signup_threebox.patientlist ul.patientlist li:nth-child(6) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 17%;
    flex: 0 0 17%;
  }
  .signup_threebox.patientlist ul.patientlist li.address {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 17%;
    flex: 0 0 17%;
  }
  .signup_threebox.patientlist ul.patientlist li.visitelist {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 17%;
    flex: 0 0 17%;
  }
  .signup_threebox ul li {
    padding: 15px 0px 15px 5px;
  }
  .signup_threebox ul.patientlist li {
    gap: 0;
  }
  .signup_threebox.practice_detailssect {
    padding: 0px 15px;
  }
  .view_file_btn {
    min-width: 100px;
  }
  .listprice2 p {
    font-size: 13px;
  }
  .signup_threebox ul li h6 {
    font-size: 15px;
  }
  .signup_threebox ul.patientlist li .smsicon {
    height: 16px;
    width: 16px;
  }
  .signup_threebox ul.patientlist li .smsicon img {
    max-width: 8px;
  }
  .signup_threebox.my_tasks ul.patientlist li {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 11% !important;
    flex: 0 0 11% !important;
  }
  .signup_threebox.my_tasks .edt_rmviconbox {
    min-width: 60px;
  }
  .signup_threebox.my_tasks ul li h6,
  .signup_threebox.table-header-wrap ul li h6 {
    font-size: 13px;
  }
  .hedconttoggel .logiconbox {
    width: 36px;
    height: 36px;
    margin-right: 6px;
  }
  .hedconttoggel button {
    font-size: 14px;
  }
  .headarstyle .nav__item a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
  }
  .search_bar .form-control {
    max-width: 180px;
  }
  .headarstyle .nav__item a {
    padding: 0px 0px 0px 20px;
  }
  .signup_threebox ul li h6 {
    font-size: 15px;
  }
}
@media (max-width: 1197px) {
  .patientsearchbar {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  /* .patientsearchbar h3.name {
    flex: 0 0 100%;
  } */
  /* .ml-auto {
    margin-left: 0px !important;
  } */
  .form-check.form-switch.onoff_area.ml-auto .form-check-label {
    font-size: 14px;
  }
}

@media (max-width: 1199px) {
  .progresssteapes li {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
  }
  .progresssteapes li h6 {
    font-size: 12px;
  }
  .progresssteapes li span {
    width: 23px;
    height: 23px;
    font-size: 12px;
  }
  .progresssteapes li h6::after {
    display: none;
  }
  .sidebarbox {
    width: 60px;
    /* height: 520px; */
  }
  .nav.dashBnav .navbar-brand {
    width: 60px;
  }
  .sidebarbox .menulist {
    width: 50px;
    height: 50px;
  }
  .menulist img {
    width: 20px;
    height: 20px;
  }
  .sidebarbox .menulist:hover .sidebarmenu {
    left: 50px;
  }
  .sidebarbox {
    z-index: 999;
  }
  .practice_hours .hours_list li.timelist {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
  }
}
@media (max-width: 991px) {
  .dropdown-menu.profiledropdown .profilelink {
    overflow: visible;
    letter-spacing: normal;
  }
  .patientsearchbar h3.name {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  /* strat allied_documents 30.06.23 */
  .dashBcontbody.alliedDocument {
    max-width: 100%;
  }
  .form-select.form-control.dpblock {
    /* max-width: 120px; */
    font-size: 12px;
  }
  .search_bar.documentsearch .form-control::-webkit-input-placeholder {
    color: #8d8da3 !important;
    font-size: 12px;
  }
  .search_bar.documentsearch .form-control::-moz-placeholder {
    color: #8d8da3 !important;
    font-size: 12px;
  }
  .search_bar.documentsearch .form-control:-ms-input-placeholder {
    color: #8d8da3 !important;
    font-size: 12px;
  }
  .search_bar.documentsearch .form-control::-ms-input-placeholder {
    color: #8d8da3 !important;
    font-size: 12px;
  }
  .search_bar.documentsearch .form-control,
  .search_bar.documentsearch .form-control::placeholder {
    color: #8d8da3 !important;
    font-size: 12px;
  }
  .dashBcontbody.alliedDocument .signup_threebox ul.patientlist li {
    padding: 9px 0px 9px 20px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .dashBcontbody.alliedDocument
    .signup_threebox
    ul.patientlist
    li:nth-child(even) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .dashBcontbody.alliedDocument .signup_threebox ul li h6 {
    font-size: 15px;
    margin-bottom: 6px;
  }
  .dashBcontbody.alliedDocument .listprice2 p {
    font-size: 13px;
  }
  .search_bar.documentsearch .form-control:hover,
  .search_bar.documentsearch .form-control:focus {
    padding-left: 35px;
  }
  /* end allied_documents 30.06.23 */

  .dashBcontbody.allied_sms
    .signup_threebox
    ul.patientlist
    li:nth-child(5n + 0)::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 1000vh;
    border-bottom: 1px solid #d5d5e6;
    bottom: 0;
    left: 0%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .dashBcontbody.allied_sms
    .signup_threebox
    ul.patientlist
    li:nth-child(6n + 0)::before {
    display: none;
  }
  .nav.dashBnav .navbar-brand {
    position: relative;
  }
  .nav.dashBnav .navbar-brand img {
    width: 55px;
  }
  .summary_heading.sms_rules {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  nav.nav.dashBnav .navbar-brand {
    margin-left: 0px;
  }
  .dashBcontbody {
    max-width: 100%;
  }
  .nav.dashBnav .navbar-brand {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    text-align: center;
  }
  .nav.dashBnav .navebg {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
  }
  .dashBnav .search_bar {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .dashBcontbody {
    margin: 0px;
  }
  .navebg {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px;
  }
  .headarstyle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
  }
  .diflexmenu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 10px;
    background: var(--green-800);
    border-radius: 25px;
    padding: 12px;
  }
  .navicon {
    width: 35px;
    padding: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 10px;
  }
  /* .navicon img {
        filter: brightness(0) invert(1);
    } */
  .headarstyle .nav__item a {
    padding: 15px 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .sidebarbox {
    width: 100%;
    position: relative;
    top: 0;
  }
  .headarstyle .nav__menu li + li {
    border-left: 0px;
  }
  .navebg,
  .headarstyle {
    background: transparent;
  }
  .nav.dashBnav,
  .containeralart {
    background: #f5f5fd;
  }
  .search_bar .form-control {
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    height: 52px;
    width: 52px;
    font-size: 0px;
    -webkit-box-shadow: 0px 1px 3px 0px #7885b226;
    box-shadow: 0px 1px 3px 0px #7885b226;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0px;
    background: url("../images/srcicon.svg") no-repeat;
    background-position: center center;
    background-color: #fff;
  }
  /* .search_bar img {
    display: none;
  } */
  .search_bar .form-control:hover,
  .search_bar .form-control:focus {
    width: 160px;
    font-size: 14px;
    padding: 0px 15px;
    background-position: 130px center;
  }
  .headarstyle {
    gap: 8px;
  }
  .logiconbox {
    display: none;
  }
  .hedconttoggel {
    background: var(--green-800);
    height: 52px;
    width: 52px;
    border-radius: 100%;
  }
  .hedconttoggel button {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    word-wrap: inherit;
    overflow: hidden;
    width: 26px;
    letter-spacing: 10px;
    line-height: normal;
  }
  .cleandarmainflexbox {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .containeralart {
    margin-bottom: 25px;
  }
  .dashBnav::after {
    display: none;
  }
  .dashBcontbody h3 {
    font-size: 20px;
    /* flex: 0 0 100%; */
    margin-bottom: 10px;
  }
  .bigclander .rbc-toolbar {
    font-size: 15px;
  }
  .smallclander .react-calendar {
    display: none;
  }
  .smallclander {
    min-width: 100%;
    background: #000000b0;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: end;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 50px 20px 0px;
  }
  .smallclanderform {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    height: 700px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .showformbtn button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .calendariconbox {
    display: none;
  }
  .headarstyle {
    padding: 11px 0px;
  }
  .dskt {
    display: none;
  }
  .smallclanderform.show {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .phondpn {
    display: none;
  }
  /* .eventDetails_edit {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateX(0);
    }
    .eventDetails_edit::before {
        content: "";
        position: fixed;
        left: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background: #000000b0;
    } */

  .bigclander .rbc-header {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 200px;
    flex: 0 0 200px;
    background: #fafafd;
  }
  .bigclander .rbc-time-slot {
    width: 200px;
  }
  .rbc-time-view {
    width: 100%;
    overflow: auto;
    float: left;
    overflow-y: hidden;
  }
  .bigclander .rbc-time-header.rbc-overflowing {
    background: transparent;
  }
  .eventflexbox {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .rbc-day-slot .rbc-event-content {
    padding: 10px 0px;
  }
  .eventflexbox h6 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .bigclander .dropdown_view {
    z-index: 1;
  }
  /* .eventDetails_edit {
    z-index: 9999;
    position: fixed;
    opacity: 1;
    top: auto;
    background: #fff;
    box-shadow: 0px 6.929748058319092px 8.08470630645752px 0px #7885b23d;
    border-radius: 20px;
    padding: 25px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 100%;
    bottom: 0;
  } */
  /* start alliedpatient 12.06.23 */
  .patientlist .listprice2 h6 {
    display: block;
  }
  .patientlist .listvat.hedoutline {
    display: none;
  }
  .shortbyselect .form-control {
    display: block;
  }
  .listvat.hedoutline .form-select.form-control {
    display: none;
  }

  .signup_threebox ul.patientlist li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    gap: 0px 7px;
    padding: 9px 0px 9px 45px;
    position: relative;
  }
  .respl0 {
    padding-left: 0px !important;
  }

  .patientlist li {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .signup_threebox ul li h6 {
    width: 100%;
  }
  .patientlist .agree_check {
    height: auto;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0px;
  }
  .signup_threebox ul {
    padding: 30px 0px 0px;
    position: relative;
    overflow: hidden;
  }
  .patientlist .agree_check .checkmark {
    left: -30px;
  }
  .sendsmstooltip {
    display: none;
  }
  .view_file_btn {
    min-width: 84px;
  }

  .patientsheading .search_bar {
    margin-left: 0px !important;
  }
  .shortbyselect .form-control {
    max-width: 100%;
  }
  .patientsearchbar .custom_btn {
    padding: 12px 14px;
    font-size: 12px;
  }
  /* .dashBcontbody {
    padding: 0px 20px;
  } */
  .patientsearchbar .search_bar img {
    display: block;
  }
  .patientsheading .search_bar .form-control {
    background: #fff;
    font-size: 12px !important;
    padding: 0px 35px;
    /* width: 100% !important;
    min-width: 100% !important; */
  }
  .patientsheading .search_bar .filterIcon {
    right: 10px;
  }
  .signup_threebox ul.patientlist li:nth-child(even) {
    padding-left: 0px;
    padding-right: 0px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
  }
  .coltoutline {
    border-bottom: 0px;
  }
  .signup_threebox ul.patientlist li:nth-child(6n + 0)::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 1000vh;
    border-bottom: 1px solid #d5d5e6;
    bottom: 0;
    left: 0%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .addpatientlist .service_details {
    padding: 15px !important;
  }
  /* .smamodal .modal-dialog-centered {
    align-items: flex-end;
  } */
  .bodycontdropdown {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .bodycontdropdown h5 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-bottom: 6px;
    font-size: 15px;
  }
  .smamodal .form-control {
    font-size: 12px;
  }
  .bodycontdropdown ul {
    width: 100%;
  }
  .bodycontdropdown ul li {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .smamodal .modal-body {
    padding: 20px;
  }
  .bodycontdropdown ul li .form-select.form-control {
    min-width: 100%;
  }
  .containeralart {
    padding: 0px 20px;
  }
  .nav.dashBnav {
    gap: 0px;
  }
  /* end alliedpatient 12.06.23 */
  /* start 14.07.23 */
  .dashBcontbody {
    max-width: 100% !important;
    margin-bottom: 50px;
  }
  .signup_threebox.my_tasks ul.patientlist li {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
    padding: 10px 10px !important;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
  }

  .signup_threebox.my_tasks .formwidth {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
  }
  .signup_threebox.my_tasks ul li h6 {
    font-size: 14px;
    margin-bottom: 6px;
  }
  .signup_threebox.my_tasks
    li.listprice2.coltoutline.editwidth.coltoutline5::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 1000vh;
    border-bottom: 1px solid #d5d5e6;
    bottom: 0;
    left: 0%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .signup_threebox.my_tasks ul.patientlist li:nth-child(6n + 0)::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 1000vh;
    border-bottom: 0px;
    bottom: 0;
    left: 0%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  li.listprice2.coltoutline.editwidth.coltoutline5 {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    padding-bottom: 30px !important;
    margin-bottom: 30px !important;
  }
  .edt_rmviconbox {
    margin-top: 15px;
    min-width: 60px !important;
  }
  .signup_threebox.my_tasks .listprice2 p {
    font-size: 14px;
    line-height: 22px;
  }
  .pagination_box {
    padding: 20px 20px 25px;
  }
  .form-check.form-switch.onoff_area.ml-auto {
    margin-left: 0;
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
    margin-top: 10px;
  }
  /* end 14.07.23 */

  /* start 08.09.23 */
  .signup_threebox.patientlist ul.patientlist li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .signup_threebox.patientlist ul.patientlist li:nth-child(3) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .signup_threebox.patientlist ul.patientlist li:nth-child(6) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .signup_threebox.patientlist ul.patientlist li.address {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .signup_threebox.patientlist ul.patientlist li.visitelist {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .signup_threebox.patientlist ul.patientlist li.flhundred {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .signup_threebox ul.patientlist li.mbd1:before {
    content: "";
    position: absolute;
    height: 1px;
    width: 1000vh;
    border-bottom: 1px solid #d5d5e6;
    bottom: 0;
    left: 0%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .signup_threebox ul.patientlist.patienul li:nth-child(6n + 0)::before {
    display: none;
  }
  .signup_threebox ul.patientlist.patienul li {
    padding: 9px 20px;
  }
  .listprice2.coltoutline.flhundred.mbd1 {
    padding-bottom: 30px;
    margin-bottom: 20px;
  }
  /* end 08.09.23 */

  /* start 03.10.23 */
  .service_details ul.patientlist.srvdtl li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .signup_threebox ul.patientlist.srvdtl li.plstyle {
    padding-left: 45px;
  }
  .service_details ul.patientlist.srvdtl li {
    padding: 14px 18px;
  }
  .service_details ul.patientlist.srvdtl li.editelist {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .service_details ul.patientlist.srvdtl li:nth-child(5n + 0)::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 1000vh;
    border-bottom: 1px solid #d5d5e6;
    bottom: 0;
    left: 0%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .signup_threebox ul.patientlist.srvdtl li:nth-child(6n + 0)::before {
    border-bottom: 0px;
  }
  .addpatientlist .service_details .patientlist.srvdtl li.coltoutline {
    border-top: 0px;
    border-bottom: 0px;
  }
  li.hours_head.forphon {
    display: block !important;
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
  }
  .practice_hours .hours_list li {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
  }
  /* .practice_hours .hours_list li.timelist {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
  } */
  .fordesktop {
    display: none;
  }
  /* end 03.10.23 */

  /* start 20.10.23 */
  .patientlist.smssettingdtl li:nth-child(even) {
    padding-left: 20px !important;
  }

  .patientlist.smssettingdtl li {
    padding-left: 20px !important;
  }
  .patientlist.smssettingdtl {
    padding-top: 0px;
  }
  /* end 20.10.23 */

  .allied-documents-wrap {
    width: 100%;
  }
  .userdetailsmodal .dtl_uploadimgsect ul,
  .userdetailsmodal .sectflex,
  .userdetailsmodal .dtl_uploadimgsect ul li {
    display: block !important;
  }
  .time-off-wrap .signup_threebox ul.patientlist li {
    padding: 10px;
    gap: 0px;
  }
  .hedconttoggel button.deletebtn.profilelink {
    padding: 0;
  }
  .signup_threebox.practice_detailssect {
    padding: 0px !important;
    background: transparent;
  }
  .ptdetalistabcont {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    background: #fff;
    padding: 15px;
    border-radius: 20px;
  }
  .detailsp_btn {
    width: 100%;
    background: var(--green-800) !important;
    color: #fff !important;
    height: 45px;
    padding: 0px 15px;
    font-weight: 600;
    font-size: 16px;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 40px;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 15px;
  }
  .tabflex.dropdown-menu {
    position: absolute !important;
    display: none !important;
    border-radius: 0;
    min-width: 100%;
    border: 0px;
  }
  .tabflex.dropdown-menu.show {
    display: block !important;
  }
  .tabflex.dropdown-menu.show {
    width: 100%;
    padding: 10px 15px;
    border: 0px !important;
    border-radius: 20px !important;
    z-index: 9999;
    -webkit-box-shadow: 0px 2.3326871395111084px 6.998061180114746px 0px
      rgba(120, 133, 178, 0.15);
    box-shadow: 0px 2.3326871395111084px 6.998061180114746px 0px
      rgba(120, 133, 178, 0.15);
  }
  .addpatientlist .user_detailsbox {
    padding: 15px !important;
  }
}

@media (max-width: 767px) {
  .dashBcontbody.addpatentdashbord {
    max-width: 100%;
  }
  .signup_threebox.addpatientlist h4 {
    font-size: 20px;
  }
  .addpatientlistcontbox {
    padding: 30px 22px 60px;
  }

  /* start practice_details 20.07.23 */
  .patientsearchbar.practice_details h3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .signup_threebox.practice_detailssect,
  .show.active.sectflex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  /* .signup_threebox.practice_detailssect {
    padding: 0px !important;
    background: transparent;
  }
  .ptdetalistabcont {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    background: #fff;
    padding: 0px 15px;
    border-radius: 20px;
  } */
  /* .detailsp_btn {
    width: 100%;
    background: var(--green-800) !important;
    color: #fff !important;
    height: 45px;
    padding: 0px 15px;
    font-weight: 600;
    font-size: 16px;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 40px;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 15px;
  }
  .tabflex.dropdown-menu {
    position: absolute !important;
    display: none !important;
    border-radius: 0;
    min-width: 100%;
    border: 0px;
  }
  .tabflex.dropdown-menu.show {
    display: block !important;
  } */
  .tabdtformsect {
    padding: 25px 0px 0px 0px !important;
  }
  .locationbox {
    max-width: 100% !important;
  }
  .tabflex {
    border-right: 0px !important;
  }
  .practicedetailssectlist .nav-item .nav-link {
    border-radius: 40px !important;
  }
  /* .tabflex.dropdown-menu.show {
    width: 100%;
    padding: 10px 15px;
    border: 0px !important;
    border-radius: 20px !important;
    z-index: 9999;
    -webkit-box-shadow: 0px 2.3326871395111084px 6.998061180114746px 0px
      rgba(120, 133, 178, 0.15);
    box-shadow: 0px 2.3326871395111084px 6.998061180114746px 0px
      rgba(120, 133, 178, 0.15);
  } */
  .signup_threebox.practice_detailssect .btnxscenter {
    text-align: center !important;
  }
  /* end practice_details 20.07.23 */

  /* start 27.07.23 */
  /* .addpatientlist .user_detailsbox {
    padding: 0px !important;
  } */
  .ptdetalistabcont.user_detailsboxtab {
    padding: 0px;
  }
  .addpatientlist .user_detailsbox .patientlist li {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 46% !important;
    flex: 0 0 46% !important;
  }
  .addpatientlist .user_detailsbox .patientlist li:nth-child(odd) {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 54% !important;
    flex: 0 0 54% !important;
  }
  .addpatientlist .user_detailsbox .patientlist li.coltoutline {
    border-top: 0px !important;
  }
  .user_detailsbox ul.patientlist li {
    padding: 14px 10px !important;
  }
  li.listprice2.coltoutline.plstyle {
    padding-left: 44px !important;
  }
  .user_detailsbox .patientlist .listvat {
    display: none !important;
  }
  .signup_threebox ul li h6 {
    font-size: 16px;
  }
  .listprice2 p,
  .form-check-label {
    font-size: 14px;
  }
  .userdetailsmodal .modal-body {
    padding: 0px 0px 20px;
  }
  .userdetailsmodal .dtl_uploadimgsect {
    padding: 20px 0px !important;
  }
  .upimgbox {
    width: 90px !important;
    height: 90px !important;
  }
  .userdetailsmodal .dtl_uploadimgsect ul {
    margin-left: 15px !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 0px !important;
  }
  .userdetailsmodal .dtl_uploadimgsect ul li label {
    font-size: 14px !important;
  }
  .userdetailsmodal .modal-dialog {
    margin: 0px;
  }
  .permissionsList li {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .permissionsList li h5 {
    width: 100% !important;
    margin-bottom: 12px !important;
  }
  .permissionsList li .form-switch {
    margin-left: 0px !important;
    margin-right: 10px !important;
  }
  .permissionsbox {
    padding: 0px !important;
    border: 0px !important;
  }
  .userdetailsmodal .dtl_uploadimgsect ul,
  .userdetailsmodal .sectflex,
  .userdetailsmodal .dtl_uploadimgsect ul li {
    display: block !important;
  }
  .userdetailsmodal .dtl_uploadimgsect ul li + li {
    margin-top: 10px !important;
  }
  .userdetailsmodal .dtl_uploadimgsect ul li label h5 {
    font-size: 14px;
  }
  .userdetailsmodal .dtl_uploadimgsect {
    -webkit-box-align: self-start !important;
    -ms-flex-align: self-start !important;
    align-items: self-start !important;
  }
  /* end 27.07.23 */

  /* start 31.07.23 */
  .servicemodalbox .onoff_area .form-check-input {
    margin: 0px !important;
  }
  .servicemodalbox label {
    width: 100%;
    display: block;
  }
  /* .addpatientlist .service_details {
    padding: 0px !important;
  } */
  .service_details ul.patientlist li {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
  }
  .service_details ul.patientlist li:nth-child(even) {
    padding-left: 18px;
  }
  .service_details ul.patientlist li {
    padding: 14px 18px !important;
  }
  .addpatientlist .service_details .patientlist li.coltoutline {
    border-top: 0px !important;
  }
  .service_details ul.patientlist li.editelist {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
  }
  .service_details ul.patientlist li:nth-child(5n + 0)::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 1000vh;
    border-bottom: 1px solid #d5d5e6;
    bottom: 0;
    left: 0%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .service_details ul.patientlist li:nth-child(6n + 0)::before {
    display: none;
  }
  .service_details ul {
    padding: 10px 0px 30px;
  }
  .addpatientlist .service_details .pagination_box {
    border-top: 0px !important;
  }
  .service_details ul.patientlist li.listvat {
    display: none;
  }
  /* end 31.07.23 */

  /* start 02.08.23 */

  .dpblock {
    display: block !important;
    margin-bottom: 0px;
  }
  .patientsheading.dpblock .patientsearchbar {
    display: block !important;
  }
  .practice_hours .hours_list {
    padding: 0px;
  }
  .practice_hours {
    margin: 20px !important;
  }
  .practice_hours .hours_list li h6,
  .practice_hours .hours_list li,
  .practice_hours .hours_list li .form-check-label,
  .practice_hours .hours_list li.timelist .input-group-text {
    font-size: 16px !important;
  }
  .practice_hours .hours_list li {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
  }
  .practice_hours .hours_list li.timelist {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
  }
  li.hours_head.forphon {
    display: block !important;
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
  }
  .practice_hours .hours_list li {
    padding: 0px 0px 26px !important;
  }
  .practice_hours .hours_list li.timelist .form-select {
    padding: 0px 10px !important;
    background-position: right 10px center !important;
  }

  .practice_hours .btnxscenter {
    margin-top: 20px !important;
  }
  /* end 02.08.23 */

  /* start 20.10.23 */
  .smslg {
    display: none !important;
  }

  /* end 20.10.23 */

  /* start 25.10.23 */
  .signup_section .log_wrap {
    padding: 0px;
  }
  /* end 25.10.23 */

  /* start 27.10.23 */
  .progresssteapes li h6::after {
    display: block;
    right: 0;
    width: 25%;
  }
  .progresssteapes li span {
    width: 26px;
    height: 26px;
    font-size: 14px;
  }
  .progresssteapes li h6 {
    font-size: 14px;
  }
  .progresssteapes li {
    display: none;
  }
  .progresssteapes li.showActive {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  /* end 27.10.23 */
  .allied-documents-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .allied-documents-wrap > div:not(:last-child) {
    margin-right: 0;
  }
  .patientsheading .search_bar {
    margin-bottom: 15px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .btnxscenter,
  .shortbyselect {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .shortbyselect {
    margin-bottom: 15px;
    margin-right: 0px !important;
  }
  .btnxscenter .custom_btn {
    width: 100%;
    padding: 15px;
    font-size: 16px;
  }
  .patientsearchbar h3.name {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .patientsearchbar .custom_btn {
    font-size: 16px;
  }
  .time-off-wrap .btnxscenter {
    margin-bottom: 15px;
  }
}
@media (max-width: 480px) {
  .btnxscenter,
  .shortbyselect {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .shortbyselect {
    margin-bottom: 15px;
    margin-right: 0px !important;
  }
  .dashBcontbody h3 {
    font-size: 18px;
  }
  .btnxscenter .custom_btn {
    padding: 11px;
    height: auto !important;
    font-size: 14px;
  }
  .time-off-wrap .signup_threebox ul {
    padding: 15px 0px 0px;
  }
}

@media (max-width: 380px) {
  .search_bar .form-control:hover,
  .search_bar .form-control:focus {
    width: 140px;
    font-size: 12px;
    padding: 0px 0px 0px 15px;
    background-position: 110px center;
  }
}
