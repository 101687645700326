.emptyState {
  align-items: center;
  background-color: var(--white);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 40px 0px;
  position: relative;
  width: 100%;
}

.emptyState .element {
  height: 160px;
  position: relative;
  width: 239.53px;
}

.emptyState .text {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 400px;
}

.emptyState .div {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.emptyState .div p {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  max-width: 320px;
  line-height: 24px;
}

.emptyState .textWrapper {
  align-self: stretch;
  color: #000000;
  font-weight: 700;
  font-size: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.emptyState .onceThePatient {
  align-self: stretch;
  color: #000000;
  position: relative;
  text-align: center;
}

.loader {
  margin-top: 24px;
  border: 8px solid var(--gray-100);
  border-top: 8px solid var(--green-800);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
