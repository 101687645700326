.patientlist li {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.patientlist li .form-group {
  position: relative;
}
.view_file_btn {
  background: var(--green-800);
  min-width: 120px;
  height: 35px;
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border: 1px solid var(--green-800);
}
.view_file_btn:hover,
.view_file_btn:focus,
.view_file_btn:active {
  background-color: transparent;
  color: var(--green-800);
}
.signup_threebox ul.patientlist li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.6%;
  flex: 0 0 16.6%;
  /* gap: 7px; */
  cursor: pointer;
}
.signup_threebox.patientlist ul.patientlist li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 13%;
  flex: 0 0 13%;
}
.signup_threebox.patientlist ul.patientlist li .icon-spacing {
  margin-right: 12px;
}
.signup_threebox.patientlist ul.patientlist li:nth-child(3) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 17.5%;
  flex: 0 0 17.5%;
}
.signup_threebox.patientlist ul.patientlist li:nth-child(6) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 17.5%;
  flex: 0 0 17.5%;
}
.signup_threebox.patientlist ul.patientlist li.address {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 17.5%;
  flex: 0 0 17.5%;
}
.signup_threebox.patientlist ul.patientlist li.visitelist {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 17.5%;
  flex: 0 0 17.5%;
}
.signup_threebox ul.patientlist li .smsicon {
  height: 35px;
  width: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100%;
  background: #ffe9f2;
}
.signup_threebox ul.patientlist li .smsicon img {
  width: 16px;
  height: 16px;
}
.patientsheading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 35px;
}
.patientsearchbar {
  display: -webkit-box;
  display: -ms-flexbox;
  margin-top: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
.patientsheading h3 {
  font-size: 30px;
  font-weight: 700;
  color: #060030;
  margin: 0px;
}
.patientsheading .search_bar .form-control {
  -webkit-box-shadow: 0px 2.3326871395111084px 6.998061180114746px 0px #7885b226;
  box-shadow: 0px 2.3326871395111084px 6.998061180114746px 0px #7885b226;
  min-width: 280px;
}

.patientsheading .search_bar .filterIcon {
  right: 20px;
  left: auto;
}

/* start 12.06.23 */
.smamodal {
  -webkit-box-shadow: 0px 6px 10px 0px #0000000f;
  box-shadow: 0px 6px 10px 0px #0000000f;
  border-radius: 18px;
}
.smamodal .modal-dialog {
  max-width: 770px;
}
.smamodal h4 {
  color: #060030;
  font-size: 20px;
  font-weight: 600;
}
.bodycontdropdown {
  width: 100%;
}
.bodycontdropdown .form-select.form-control {
  min-width: 150px;
  height: 60px;
  border-radius: 9px;
  border: 1px solid var(--gray-400);
  font-size: 14px;
  color: #8d8da3;
}
.bodycontdropdown label {
  color: #000;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}
.bodycontdropdown .form-control.textareaform-control {
  border-radius: 12px;
  border: 1px solid #ebebeb;
  font-size: 16px;
  padding: 20px;
}
.bodycontdropdown ul li .form-select.form-control::-webkit-input-placeholder {
  color: #8d8da3;
}
.bodycontdropdown ul li .form-select.form-control::-moz-placeholder {
  color: #8d8da3;
}
.bodycontdropdown ul li .form-select.form-control:-ms-input-placeholder {
  color: #8d8da3;
}
.bodycontdropdown ul li .form-select.form-control::-ms-input-placeholder {
  color: #8d8da3;
}
.bodycontdropdown ul li .form-select.form-control::placeholder {
  color: #8d8da3;
}
.smamodal .modal-footer .custom_btn {
  /* width: 100%; */
  font-size: 16px;
  font-weight: 500;
}
.smamodal .modal-footer {
  border: 0px;
  padding: 0 26px 30px;
  justify-content: flex-start;
}
.smamodal .modal-body {
  padding: 20px 26px;
}
.sendsmstooltip {
  -webkit-box-shadow: 0px 0px 5px 0px #0000001a !important;
  box-shadow: 0px 0px 5px 0px #0000001a !important;
  font-size: 12px;
  color: #545a63 !important;
  border-radius: 5px !important;
  padding: 14px 12px !important;
  z-index: 1;
  background: #fff !important;
}
.listprice2 h6 {
  display: none;
}
.shortbyselect .form-control {
  height: 52px;
  max-width: 110px;
  border-radius: 26px;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  border: 0px;
  -webkit-box-shadow: 0px 2.006216287612915px 6.018648624420166px 0px #7885b226;
  box-shadow: 0px 2.006216287612915px 6.018648624420166px 0px #7885b226;
  display: none;
}
.listprice2 p {
  font-size: 15px;
}
/* .patientsheading .search_bar {
    margin-left: auto;
} */
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.noresults_found {
  padding: 15px;
  font-size: 15px;
  color: #060030;
  font-weight: 600;
}
/* end 12.06.23 */

/* start 29.06.23 */
.sortButton {
  border: 0;
  background: transparent;
  height: auto;
  line-height: normal;
}
.form-select.form-control.dpblock {
  display: block;
  /* min-width: 186px;
  max-width: 186px; */
  color: #8d8da3;
  font-size: 14px;
}
.search_bar.documentsearch .form-control::-webkit-input-placeholder {
  color: #8d8da3 !important;
  font-size: 14px;
}
.search_bar.documentsearch .form-control::-moz-placeholder {
  color: #8d8da3 !important;
  font-size: 14px;
}
.search_bar.documentsearch .form-control:-ms-input-placeholder {
  color: #8d8da3 !important;
  font-size: 14px;
}
.search_bar.documentsearch .form-control::-ms-input-placeholder {
  color: #8d8da3 !important;
  font-size: 14px;
}
.search_bar.documentsearch .form-control,
.search_bar.documentsearch .form-control::placeholder {
  color: #8d8da3 !important;
  font-size: 14px;
}
.colordark {
  color: #060030;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.dot1 {
  position: relative;
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-right: 3px;
}
/* end 29.06.23 */

/* start 04.07.23 Add Patients */
.signup_threebox.addpatientlist {
  margin-bottom: 50px;
}
.add_patientsbtn {
  padding: 0px;
  margin: 12px 0px 0px;
  border: 0px;
  background: transparent;
  color: #060030;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  transition: 0.3s ease all;
  display: flex;
  align-items: center;
}
.add_patientsbtn:hover {
  color: var(--green-800);
}
.add_patientsbtn img {
  transition: 0.3s ease all;
  margin-right: 10px;
}
.add_patientsbtn:hover img {
  transform: translateX(-10px);
}
.signup_threebox.addpatientlist h4 {
  color: #060030;
  font-size: 22px;
  line-height: normal;
  font-weight: 500;
  margin-bottom: 22px;
}
.addpatientlistcontbox {
  padding: 30px 40px 60px;
}
.signup_threebox.addpatientlist label {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 500;
}
.signup_threebox.addpatientlist .form-control {
  height: 60px;
  font-size: 14px;
  padding: 0px 15px;
}
.signup_threebox.addpatientlist .form-control::-webkit-input-placeholder {
  color: #8d8da3;
}
.signup_threebox.addpatientlist .form-control::-moz-placeholder {
  color: #8d8da3;
}
.signup_threebox.addpatientlist .form-control:-ms-input-placeholder {
  color: #8d8da3;
}
.signup_threebox.addpatientlist .form-control::-ms-input-placeholder {
  color: #8d8da3;
}
.signup_threebox.addpatientlist .form-control::placeholder {
  color: #8d8da3;
}
.custom_btn.savwidth {
  padding: 12px 34px;
}
/* .savwidth {
  min-width: 257px;
  height: 60px;
} */
.addpatientlistcontbox .MuiStack-root {
  padding-top: 0;
}
.addpatientlistcontbox .MuiInputBase-root {
  border: 1px solid var(--gray-400);
  border-radius: 12px;
}
.addpatientlistcontbox .MuiInputBase-input {
  padding: 18.5px 14px;
}
.addpatientlistcontbox fieldset {
  display: none;
}
/* end 04.07.23 Add Patients */

/* start 11.07.23 my_tasks */
.signup_threebox.my_tasks ul.patientlist li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 11%;
  flex: 0 0 11%;
  /* padding: 15px 10px; */
}
.signup_threebox.my_tasks ul li h6 {
  font-size: 16px;
}
.signup_threebox.my_tasks .formwidth {
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 7% !important;
  flex: 0 0 7% !important;
}
.signup_threebox.my_tasks .taskwidth {
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 21% !important;
  flex: 0 0 21% !important;
}
.signup_threebox.my_tasks .listprice2 p {
  font-size: 14px;
}
.signup_threebox.my_tasks .statuswidth {
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 10% !important;
  flex: 0 0 10% !important;
}
.signup_threebox.my_tasks .edt_rmviconbox {
  min-width: 100px;
}
/* end 11.07.23 my_tasks */

/* start 13.07.23 */
.ml-auto {
  margin-left: auto;
}
.ml-0 {
  margin-left: 0px !important;
}
.addTaskmodal .react-calendar__month-view__days button abbr {
  height: 26px;
  font-size: 14px;
}
.addTaskmodal .react-calendar__tile--now,
.addTaskmodal .react-calendar__tile--now:focus {
  background: var(--green-800);
  color: #fff !important;
}
.addTaskmodal .react-calendar__tile--now:hover {
  background: var(--green-800);
}
.addTaskmodal .react-calendar__navigation {
  margin: 0px;
}
.addTaskmodal .react-calendar__month-view__weekdays__weekday {
  padding: 5px 0px;
  font-size: 12px;
}
.addTaskmodal .calendar-container {
  position: absolute;
}
.addTaskmodal .react-calendar__navigation button {
  min-width: 30px;
  background: none;
  text-align: left;
}
.addTaskmodal
  span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-size: 18px;
}
/* .dashBcontbody {
  margin-bottom: 80px;
} */
.addtaskdrop .btndrop {
  padding: 0px;
  margin: 0px;
  color: #8d8da3;
}
.addtaskdrop .btndrop:active,
.btndrop:focus {
  border: 0px;
  outline: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.addtaskdrop .dropdown-menu li {
  padding: 7px 15px !important;
}
.addtaskdrop .dropdown-menu li .dropdown-item {
  padding: 0px;
  font-size: 14px;
  color: #8d8da3;
}
/* end 13.07.23 */

/* start 14.08.23 */
.cancelglobal {
  border-radius: 50px;
  background: #ececfa;
  border: 2px solid #ececfa;
  color: #78789b;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 34px;
  line-height: normal;
}
.cancelglobal:hover {
  background: transparent;
  border: 2px solid #78789b;
}
.profile-btn-wrap button {
  min-width: 130px;
  max-width: 130px;
}
.gap12 {
  margin-left: 12px;
}

.patient-name-wrap {
  outline: none;
  background-color: transparent;
  border: none;
  padding: 0;
  font-weight: 500;
  -webkit-transition: 0.3s ease all;
  -o-transition: 0.3s ease all;
  transition: 0.3s ease all;
}
.patient-name-wrap:hover {
  font-weight: 600;
  text-decoration: underline;
}
.patient-title-wrap {
  color: #8d8da3;
  -webkit-transition: 0.3s ease all;
  -o-transition: 0.3s ease all;
  transition: 0.3s ease all;
}
.patient-title-wrap:hover {
  font-weight: 500;
}

.allied-documents-wrap > div:not(:last-child) {
  margin-right: 15px;
}

.allied-documents-wrap .form-select.form-control.dpblock {
  min-width: 160px;
  max-width: 160px;
}
/* end 14.08.23 */
.my-task-wrap .form-check.form-switch.onoff_area {
  margin-right: 15px;
}

.clinicalNotesect textarea::-webkit-input-placeholder {
  color: rgba(141, 141, 161, 0.5);
}

.clinicalNotesect textarea::-moz-placeholder {
  color: rgba(141, 141, 161, 0.5);
}

.clinicalNotesect textarea:-ms-input-placeholder {
  color: rgba(141, 141, 161, 0.5);
}

.clinicalNotesect textarea::-ms-input-placeholder {
  color: rgba(141, 141, 161, 0.5);
}

.clinicalNotesect textarea::placeholder {
  color: rgba(141, 141, 161, 0.5);
}
.signup_threebox.table-header-wrap ul li h6 {
  font-size: 16px;
}
.custom-checkbox-wrapper {
  display: flex;
  align-items: center;
  padding-top: 0;
}
.custom-checkbox-wrapper .form-check-input {
  width: 20px;
  height: 20px;
  margin-top: 0;
  box-shadow: none;
}
.custom-checkbox-wrapper .form-check-input:checked {
  background-color: var(--green-800);
  border-color: var(--green-800);
}
.custom-checkbox-wrapper .form-check-label {
  margin-bottom: 0 !important;
  line-height: initial;
}

/* Responsive Design */
@media only screen and (max-width: 1799px) {
  .signup_threebox.patientlist ul.patientlist li.visitelist,
  .signup_threebox.patientlist ul.patientlist li:nth-child(6) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
  }
  .signup_threebox.patientlist ul.patientlist li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 14%;
    flex: 0 0 14%;
  }
}
@media only screen and (max-width: 1599px) {
  .signup_threebox.patientlist ul.patientlist li.visitelist,
  .signup_threebox.patientlist ul.patientlist li:nth-child(6) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
  }
  .signup_threebox.patientlist ul.patientlist li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 13%;
    flex: 0 0 13%;
  }
  .patientsheading h3 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 1439px) {
  .patientsheading h3 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 1365px) {
  .cancelglobal {
    font-size: 14px;
  }
  .signup_threebox.my_tasks .listprice2 p,
  .patient-title-wrap {
    font-size: 13px;
  }
  .noresults_found {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1199px) {
  .signup_threebox.patientlist ul.patientlist li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .signup_threebox.patientlist ul li.listprice2.coltoutline {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .signup_threebox.patientlist ul li.listprice2.coltoutline.flhundred.mbd1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .signup_threebox.patientlist ul.patientlist li.address,
  .signup_threebox.patientlist ul.patientlist li.visitelist {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
  }
  .coltoutline {
    border-bottom: 0px;
  }
  .patientlist .listvat.hedoutline {
    display: none;
  }
  .signup_threebox ul.patientlist.patienul li {
    padding: 9px 20px;
  }
  .signup_threebox ul.patientlist li.mbd1 {
    position: relative;
  }
  .listprice2.coltoutline.flhundred.mbd1 {
    padding-bottom: 30px;
    margin-bottom: 20px;
  }
  .signup_threebox ul.patientlist li.mbd1:before {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #d5d5e6;
    bottom: 0;
    left: 0;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .signup_threebox ul {
    padding: 30px 0px 0px;
    position: relative;
    overflow: hidden;
  }
  .custom_btn.savwidth {
    padding: 10px 26px;
  }
}

@media only screen and (max-width: 991px) {
  .smamodal .modal-footer {
    padding: 0 20px 25px;
  }
}

@media only screen and (max-width: 767px) {
  .patientsheading .search_bar .form-control {
    min-width: 100%;
  }
  .signup_threebox.addpatientlist {
    margin-bottom: 40px;
  }
  .patientsheading {
    margin-bottom: 15px;
  }
  .profile-content-wrap.signup_threebox.addpatientlist {
    margin-left: 0px;
  }
  .tabdtformsect.profile-content-wrap {
    padding: 20px 15px !important;
  }
  .profile-content-wrap {
    margin-top: 20px;
  }
  .smamodal .modal-footer .custom_btn {
    font-size: 16px;
  }
}
@media only screen and (max-width: 414px) {
  .profile-btn-wrap button {
    min-width: 110px;
    max-width: 110px;
  }
}
