.container {
  position: relative;
}

.selectButton {
  padding: 8px 16px;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  color: #212529;
  cursor: pointer;
  transition: all 0.2s;
}

.selectButton:hover {
  background: #e9ecef;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modalHeader {
  padding: 20px;
  border-bottom: 1px solid #dee2e6;
}

.modalHeader h3 {
  margin: 0 0 16px 0;
  color: #212529;
}

.searchWrapper {
  position: relative;
}

.searchIcon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
}

.searchInput {
  width: 100%;
  padding: 8px 12px 8px 40px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 14px;
}
.templateListWrapper {
  margin-left: -24px;
  margin-right: -24px;
}

.templateList {
  overflow-y: scroll;
  flex: 1;
  max-height: 60vh;
  padding: 8px 16px 0 24px;
  padding-bottom: 0 !important;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  margin: 16px 0;
}

.templateItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  margin-bottom: 12px;
  transition: all 0.2s;
}

.templateItem:hover {
  background: #f8f9fa;
}

.templateInfo h4 {
  margin: 0;
  color: #212529;
  font-size: 16px;
}

.templateDate {
  margin: 4px 0 0 0;
  color: #6c757d;
  font-size: 12px;
}

.useButton {
  padding: 8px 16px;
  background: #328170;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.useButton:hover {
  background: #2a6b5d;
}

.loading,
.noResults {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  color: #6c757d;
  flex-direction: column;
  gap: 12px;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.openModalButton {
  background-color: white;
  color: var(--green-800);
  border: 1px solid var(--green-800);
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  height: 48px;
  width: 350px;
  margin-top: 4px;
}

.openModalButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button {
  border-radius: 12px;
  background-color: var(--green-900);
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  box-sizing: border-box;
  text-align: left;
  color: #fdfdfd;
  cursor: pointer;
  border: none;
}
