.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 480px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.secondaryTextContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #26252c;
}

.signUpContainer {
  display: flex;
  gap: 8px;
}

.signUpLink {
  font-size: 16px;
  font-weight: 500;
  color: #01533b;
}

.title {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
}

.form {
  display: flex;
  flex-direction: column;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 16px;
  font-weight: 500;
  color: #26252c;
  margin-bottom: 8px;
}

.forgotPassword {
  margin-left: auto;
  color: #01533b;
  font-weight: 600;
  line-height: 20px;
  font-size: 16px;
  margin-top: 4px;
}

.submitBtn {
  background: #01533b;
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 24px;
  border-radius: 12px;
  cursor: pointer;
  border: none;
  margin-top: 24px;
}

@media screen and (min-width: 600px) {
  .secondaryTextContainer {
    flex-direction: row;
  }
}
