.attachmentIcon {
  display: flex;
  padding: 12px;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--gray-950);
  background: var(--white);
  width: 40px;
  height: 40px;
}

.attachmentIcon img {
  width: 16px;
  height: 16px;
}

.attachmentWrapper {
  display: grid;
  align-items: center;
  grid-template-columns: 40px 1fr 100px;
  gap: 24px;
  padding: 12px 0;
  border-bottom: 1px solid var(--gray-200);
}

.attachmentWrapper:first-child {
  margin-top: 8px;
  border-top: 1px solid var(--gray-200);
}

.attachmentWrapper:last-child {
  border-bottom: none;
}

.attachmentButton {
  border: none;
  background-color: transparent;
  width: 42px;
  height: 42px;
  padding: 0;
  transform: translateX(12px);
}

.attachmentContentWrapper {
  display: flex;
  flex-direction: column;
}

.attachmentContentWrapper > h5 {
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.attachmentContentWrapper > div > div {
  display: flex;
  flex-direction: column;
  padding-right: 8px;
}

.attachmentContentWrapper h6 {
  color: var(--gray-800);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.attachmentContentWrapper p {
  color: var(--gray-800);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.prescriptionContentGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.refferralContentGrid {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.resultContentGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
