.recurring-modal.custom-modal-wrap.ant-modal {
  font-family: "Montserrat", sans-serif !important;
}
.recurring-modal.custom-modal-wrap .ant-modal-content {
  padding: 0;
}
.recurring-modal.custom-modal-wrap .ant-modal-close {
  color: rgba(0, 0, 0, 1);
  top: 25px;
}
.recurring-modal.custom-modal-wrap .ant-modal-header {
  padding: 19px 30px;
  border-radius: 18px 18px 0 0;
  margin-bottom: 0px;
}
.recurring-modal.custom-modal-wrap .ant-modal-header .ant-modal-title {
  color: var(--stratos);
  font-weight: 600;
  font-size: 24px;
}
.recurring-modal.custom-modal-wrap .ant-modal-body {
  padding: 25px 34px 50px;
}
.recurring-modal.custom-modal-wrap {
  width: 1440px !important;
}
.recurring-form-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
.recurring-modal.custom-modal-wrap .form-spacing:not(:last-child) {
  margin-bottom: 22px;
}
.recurring-modal.custom-modal-wrap form .grid-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 22px;
}
.recurring-modal.custom-modal-wrap form .grid-item-1 {
  display: grid;
  grid-template-columns: 60% 36%;
  grid-gap: 22px;
}
.recurring-modal.custom-modal-wrap .checkbox-wrapper {
  width: 70%;
}
.recurring-modal.custom-modal-wrap .weekdays-checkbox-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 22px 60px;
}
.recurring-modal.custom-modal-wrap .custom_btn.savwidth {
  font-size: 16px;
}
.recurring-form-preview-wrap {
  border-radius: 20.994px;
  border: 1.166px solid #dedeeb;
  background: #fff;
}
.recurring-form-preview-wrap .title-wrap {
  padding: 17px;
  border-radius: 20.994px 20.994px 0px 0px;
  border-bottom: 1.166px solid #dedeeb;
  background: #e9e9f6;
}

.recurring-form-preview-wrap .title-wrap h5 {
  font-size: 22px;
}
.recurring-form-itemlist {
  padding: 30px;
  border-bottom: 1px solid rgba(213, 213, 229, 0.5);
}
.recurring-form-itemlist .custom_btn.danger {
  min-width: 76px;
  max-width: 76px;
  border-radius: 6px;
  height: 30px;
  display: flex;
  font-size: 11px;
  align-items: center;
  justify-content: center;
}
.recurring-form-itemlist h6 {
  font-size: 18px;
  margin-right: 21px;
}

.recurring-form-itemlist .header-wrap {
  margin-bottom: 30px;
}
.recurring-form-itemlist .name-wrap {
  max-width: 70%;
}
.recurring-form-itemlist .bottom-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
}
.recurring-form-itemlist .bottom-wrap span {
  display: block;
  line-height: normal;
  font-size: 16px;
  word-break: break-word;
}
.recurring-form-itemlist .bottom-wrap span.label-wrap {
  margin-bottom: 7px;
  font-size: 15px;
}
.recurring-modal.custom-modal-wrap .custom-checkbox-wrap .ant-checkbox + span {
  font-weight: 400;
}
.recurring-modal.custom-modal-wrap .MuiInputBase-input {
  padding: 14.5px 20px;
}

/* Responsive Design Start */
@media only screen and (max-width: 1599px) {
  .recurring-form-preview-wrap .title-wrap h5 {
    font-size: 20px;
  }
  .recurring-form-itemlist h6 {
    font-size: 16px;
    margin-right: 16px;
  }
}
@media only screen and (max-width: 1365px) {
  .recurring-modal.custom-modal-wrap .ant-modal-header {
    padding: 16px 20px;
    border-radius: 12px 12px 0 0;
  }
  .recurring-modal.custom-modal-wrap .ant-modal-header .ant-modal-title {
    font-size: 21px;
  }
  .recurring-modal.custom-modal-wrap .ant-modal-body {
    padding: 20px 20px 30px;
  }
  .recurring-form-itemlist .bottom-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
  .recurring-modal.custom-modal-wrap {
    width: 1100px !important;
  }
  .recurring-form-preview-wrap .title-wrap h5 {
    font-size: 17px;
  }
  .recurring-form-itemlist {
    padding: 20px;
  }
  .recurring-form-wrap {
    grid-gap: 20px;
  }
  .recurring-form-itemlist .header-wrap {
    margin-bottom: 20px;
  }
  .recurring-modal.custom-modal-wrap .weekdays-checkbox-wrapper {
    grid-gap: 22px 35px;
  }
}

@media only screen and (max-width: 1199px) {
}
@media only screen and (max-width: 991px) {
  .recurring-form-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (max-width: 767px) {
}
@media only screen and (max-width: 575px) {
  .recurring-modal.custom-modal-wrap form .grid-item-1,
  .recurring-modal.custom-modal-wrap form .grid-item {
    grid-template-columns: repeat(1, 1fr);
  }
  .recurring-modal.custom-modal-wrap .weekdays-checkbox-wrapper {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
  .recurring-modal.custom-modal-wrap .checkbox-wrapper {
    width: 100%;
  }
  .recurring-form-itemlist .bottom-wrap span.label-wrap {
    font-size: 14px;
  }
  .recurring-form-itemlist .bottom-wrap span {
    font-size: 15px;
  }
  .recurring-form-preview-wrap .title-wrap h5 {
    font-size: 16px;
}
  .recurring-form-itemlist h6 {
    margin-right: 10px;
}
}



/* Responsive Design End */
