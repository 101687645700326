.dataInformation {
  display: flex;
  align-items: center;
  gap: 82px;
}
.contentWrapper {
  height: 100%;
  overflow: scroll;
}
.historyData {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.descrWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}
.descrRelationship {
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  color: black;
}
.descrMedicalDetails {
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  color: black;
}
.descrContainer {
  display: flex;
  align-items: center;
}
.numberContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  background: #e5eef7;
  border-radius: 50%;
  background-color: var(--green-800);
}
.number {
  font-weight: 600;
  letter-spacing: -0.04em;
  text-align: center;
  color: #fff;
}
.buttonIcons {
  display: flex;
  margin-left: auto;
  gap: 19px;
  align-items: center;
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
  img {
    width: 20px;
    height: 20px;
  }
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  p {
    font-size: 16px;
  }
}
.textarea {
  padding: 20px;
  border: 1px solid var(--gray-400);
  border-radius: 12px;
  max-width: 500px;
  resize: none;
}
.saveButton {
  background-color: var(--green-800);
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}
.addButton {
  border: none;
}
