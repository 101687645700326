.wrapper {
  display: flex;
  gap: 24px;
}
.firstColumnWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 24px;
  flex: 1;
}
.titleWrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
}
.secondColumnWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 2;
}
.secondColumnSubWrapper {
  display: flex;
  gap: 24px;
}
.thirdColumnWrapper {
  display: flex;
  gap: 24px;
}

@media screen and (max-width: 1800px) {
  .thirdColumnWrapper {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1620px) {
  .secondColumnSubWrapper {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1440px) {
  .secondColumnWrapper {
    flex: 1;
  }
}
@media screen and (max-width: 1200px) {
  .wrapper {
    flex-direction: column;
  }
}
