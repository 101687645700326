
.searchbox {
    padding-left: 15px;
    padding-right: 15px;
}

.searchButton {
    border: 1px solid var(--green-800);
    background: var(--white);
    color:  var(--green-800);;
    font-size: 16px;
    cursor: pointer;
    border-radius: 12px;
    padding: 12px 24px;
    width: 237px;
    height: 48px;
}

.searchButton:hover {
    background: var(--green-800);
    color:  var(--white);
}

.saveButton {
    background-color: var(--green-800);
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 12px;
    padding: 12px 24px;
    width: 237px;
    height: 48px;
    float: right;
}

.title {
    font-size: 20px !important;
}

.saveButton:hover {
    text-decoration: underline;
}