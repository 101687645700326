.actionBtn {
  border: 1px solid var(--green-900);
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
  width: 100%;
}

.drawerHeader {
  width: 570px;
  min-width: 40vw;
  max-width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
}

.headerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerTitle button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.drawerHeader h2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.content {
  flex-grow: 1;
}

.toggleBtnGroup {
  border-radius: 12px !important;
  border: 1px solid var(--Gray-200, #d9d8df) !important;
  background: var(--White, #fdfdfd) !important;
  width: 100% !important;
}

.toggleBtn {
  color: var(--Gray-950, #26252c) !important;
  font-family: Nunito !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-transform: none !important;
  width: calc(25% + 1px) !important;
  border: none !important;
  border-radius: 12px !important;
  height: 52px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: transparent !important;
  border: 0px solid var(--Green-700, #008056) !important;
  transition: none !important;
  animation: none !important;
}

.toggleBtn[aria-pressed="true"] {
  border-radius: 12px;
  border: 1px solid var(--Green-700, #008056) !important;
  background: var(--Green-50, #e7efeb) !important;
  color: var(--Green-700, #008056) !important;
}

.tableTitle {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 16px;
}

.medicalHistoryWrapper {
  margin-top: 32px;
}
