.errorText {
    list-style-type: circle;
    font-size: 14px;
    line-height: 15px;
    font-weight: 400;
    margin-left: 20px;
    color: #ef0064;
    margin-top: 5px;
}

.badiconimg {
    float: left;
}

.errorIcon {
    float: left;
}

.badiconbox {
    background: #fff0f6;
    border: 1px solid #ef0064;
    border-radius: 6px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 9px;
    padding-left: 10px;
    padding-top: 10px;
    width: 98%;
    margin-bottom: 10px;
    padding: 20px;
}

.badiconboxH6 {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: #ef0064;
    margin: 0px;
    padding-left: 5px;
    float: left;

}
