.payments-modal.custom-modal .ant-modal-footer > .ant-btn + .ant-btn {
  margin-right: 0;
}
.payments-modal.custom-modal .ant-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.payments-modal.custom-modal .ant-modal-footer .ant-btn:not(:last-child) {
  margin-right: 20px;
}
.payments-modal.custom-modal .ant-btn.custom_btn {
  background: var(--caribbean-green);
  color: #fff !important;
}
.payments-modal.custom-modal .ant-btn.custom_btn:hover,
.payments-modal.custom-modal .ant-btn.custom_btn:focus,
.payments-modal.custom-modal .ant-btn.custom_btn:active {
  background-color: #04ad70 !important;
}
.payments-modal.custom-modal .ant-form {
  font-family: "Montserrat", sans-serif !important;
}


@media only screen and (max-width: 575px) {
  .payments-modal.custom-modal .ant-modal-footer .ant-btn:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 10px;
  }
}
