.formFieldError input,
.formFieldError select,
.formFieldError textarea {
  border-color: var(--alert, #b70000) !important;
  box-shadow: 0px 0px 0px 4px rgba(239, 68, 68, 0.25) !important;
}
.formFieldError p {
  color: var(--alert, #b70000);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 5px;
}
