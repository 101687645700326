.tableCell:hover .appointmentDetails {
  opacity: 1;
  visibility: visible;
}

.tableCell:hover .appointmentDetails {
  opacity: 1;
  visibility: visible;
  transition-delay: 500ms;
}

.tableCell:not(:hover) .appointmentDetails {
  transition-delay: 0ms;
}

.appointmentDetails {
  opacity: 0;
}

.appointmentCard {
  overflow: visible;
  border: 1px solid #dbdbe9;
  font-size: 12px;
  color: #000;
  padding: 2px 5px 5px 10px;
  flex-grow: 1;
  height: 100%;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.appointmentCardHeader {
  display: flex;
  width: 100%;
  align-items: center;
  max-height: 36px;
  justify-content: space-between;
}
