.consultationButton {
  background: var(--green-800);
  border: 1px solid var(--green-900);
  border-radius: 12px;
  padding: 12px 24px;
  width: 180px;
  height: 48px;
  color: var(--white);
}

.consultationWrapper {
  display: grid;
  grid-template-columns: 64px 1fr 18px;
  gap: 24px;
  width: 100%;
  padding: 12px 0;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--gray-200);
}

.consultationWrapper:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.showMoreButtonWrapper {
  position: relative;
  width: 18px;
  height: 18px;
}

.showMoreButton {
  position: absolute;
  top: -11px;
  left: -11px;

  border: none;
  background-color: transparent;
  width: 40px;
  height: 40px;
  padding: 0;
  transform-origin: 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.showButton {
  position: absolute;
  left: -55px;

  border: none;
  background-color: transparent;
  width: 50px;
  height: 20px;
  padding: 0;
  font-size: 15px;
  transform-origin: 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
}

.showDate {
  position: absolute;
  right: 120px;
}

.showMoreButtonExpanded {
  transform: rotate(180deg);
}

.consultationContentWrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.consultationContentWrapper > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 24px;
}

.startConsultationsWarpper {
  width: 100%;
  padding-right: 12px;
  padding-top: 26px;
}

.consultationTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.consultationTitleWrapper h2 {
  color: var(--gray-950);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
}

.consultationTitleWrapper > div {
  display: flex;
  gap: 8px;
  align-items: center;
}

.consultationTitleWrapper button {
  background-color: transparent;
  padding: 0 4px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 4px;
  color: var(--green-900);
  height: 24px;
}

.consultationTitleWrapper p {
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.consultationText {
  color: var(--Gray-950, #26252c);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
}

.consultationTextExpanded {
  white-space: wrap;
  overflow: visible;
}

.attachmentsInfo {
  display: flex;
  gap: 16px;
  margin-top: 2px;
}

.attachmentsInfo p {
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.attachmentsInfo img {
  width: 16px;
  height: 16px;
}
