.addButtonWrapper {
    margin-bottom: 10px;
    width: 100%;
}
.topWrapper {
    align-items: center;
    background-color: var(--white);
    border: 1px solid;
    border-color: var(--gray-200);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 25px 25px 25px 25px;
    position: relative;
    width: 100%;
    min-height: 800px;
}
.titleWrapper {
    display: flex;
    align-items: center;
    gap: 11px;
}
.title {
    font-weight: 600;
    font-size: 20px !important;
    color: var(--black);
}
.button {
    background: none;
    border: none;
    cursor: pointer;
}
.icon {
    width: 16px;
    height: 16px;
}
.addButton {
    border: none;
}

