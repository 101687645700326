.listContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.listItemContainer {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  gap: 35px;
  border-bottom: 1px solid var(--gray-200);
  align-items: center;
}
.listItemContainer:last-child {
  border-bottom: none;
}
.listItemTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 143%;
  color: var(--gray-950);
}
.listItemDescr {
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  text-align: right;
  color: var(--gray-950);
}
.inputWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: start;
  align-items: center;
  gap: 20px;
  p {
    font-size: 16px;
  }
}
.saveButton {
  background-color: var(--green-800);
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}
.editButton {
  border: none;
}
