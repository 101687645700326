.warningmodal_box {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100% - 1rem);
}

.btnClose {
  padding: 16px !important;
}

.modal-body {
  text-align: center;
  padding: 20px;
}

.worningimg {
  width: 100px;
  height: 100px;
  background: #fff0f0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
}

.worningimg img {
  width: 50px;
  height: 50px;
}

.deletext h5 {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #333 !important;
  margin-top: 32px !important;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 0 24px 24px 24px;
}

.cancelbtnlink {
  color: var(--green-800);
  background-color: white;
  border: 1px solid var(--green-800);
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  height: 48px;
  font-weight: 600;
  min-width: 180px;
  flex-grow: 1;
}

.yesbtn {
  color: white;
  background-color: var(--green-800);
  border: 1px solid var(--green-800);
  min-width: 180px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  height: 48px;
  font-weight: 600;
  flex-grow: 1;
}
