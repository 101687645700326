.addTaskForm {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  margin-top: 24px;
}

.sendButton {
  background-color: var(--green-800);
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 180px;
  height: 48px;
}

.formCheckboxLabel {
  color: var(--gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  align-items: center;
  display: flex;
  gap: 8px;
}

.addTaskFormLabel {
  color: var(--Gray-950) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  margin-bottom: 2px !important;
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  gap: 4px !important;
}

.inputGroupGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.addTaskForm input,
.addTaskForm select,
.addTaskForm textarea {
  height: 48px !important;
  border-radius: 12px !important;
  color: var(--gray-500, #717186) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  padding: 12px !important;
  box-sizing: border-box !important;
}

.addTaskForm .MuiAutocomplete-input {
  height: 48px !important;
  border-radius: 12px !important;
  color: var(--gray-500, #717186) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  padding: 12px !important;
  box-sizing: border-box !important;
  box-shadow: none !important;
}

.addTaskFormAutocompleteWrapper > div:nth-child(2) > div {
  padding: 0 !important;
  height: 48px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: var(--gray-500, #717186) !important;
  box-sizing: border-box !important;
  border: 1px solid var(--gray-400) !important;
  border-radius: 12px !important;
  font-family: "nunito" !important;
  font-size: 14px !important;
  background-color: var(--white) !important;
}

.addTaskFormAutocompleteWrapper > div:nth-child(2) > div > div {
  height: 48px !important;
  border: none !important;
  border-radius: 0 !important;
}

.addTaskFormAutocompleteWrapper > div:nth-child(2) > div > div fieldset {
  border: none !important;
}

.addTaskFormAutocompleteWrapper > div:nth-child(2) > div > div input {
  box-shadow: none !important;
}

.addTaskFormAutocompleteWrapper > div:nth-child(2) {
  height: 48px !important;
  border: none !important;
  border-radius: 0 !important;
}

.bottomSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
}

.bottomSection > div {
  display: flex;
  gap: 16px;
}

.bottomSection button {
  width: 248px;
}

.addPregnancyDatePickerWrapper > div > div {
  height: 48px;
  border: 1px solid var(--gray-400);
  border-radius: 12px;
  font-family: "nunito";
  font-size: 14px;
  background-color: var(--white);
}

.addPregnancyDatePickerWrapper fieldset {
  border: none;
}
