.dataInformation {
  display: flex;
  align-items: center;
  gap: 82px;
}
.dataContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.descrWrapper {
  max-width: 426px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.numberContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  background: #e5eef7;
  border-radius: 50%;
  background-color: var(--green-800);
}
.number {
  font-weight: 600;
  letter-spacing: -0.04em;
  text-align: center;
  color: #fff;
}
.listContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.listItemContainer {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  gap: 35px;
  border-bottom: 1px solid var(--gray-200);
  align-items: center;
}
.listItemContainer:last-child {
  border-bottom: none;
}
.listItemTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 143%;
  color: var(--gray-950);
}
.buttonIcons {
  display: flex;
  margin-left: auto;
  gap: 13px;
  align-items: center;
  button {
    background: none;
    border: none;
  }
}
.inputWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: start;
  align-items: center;
  gap: 20px;
  p {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--gray-950);
  }
}
.checkIcon {
  width: 25px;
  height: 25px;
  fill: var(--green-800);
}
.errorIcon {
  width: 25px;
  height: 25px;
  fill: #b70000;
}
.saveButton {
  background-color: var(--green-800);
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}
.editButton {
  border: none;
}
