.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 500px;
  width: 100%;
}

.button {
  border: none;
}

.buttonContainer {
  display: flex;
  gap: 20px;
}
