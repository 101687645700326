.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
  gap: 18px;
  border: 1px solid var(--gray-200);
  background: #fff;
  border-radius: 12px;
  height: 52px;
  margin-top: 16px;
}

.navLinks {
  display: flex;
  align-items: center;
}

.navLink {
  padding: 16px 20px;
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid transparent;
  color: #25204a;
}

.navLink span {
  font-size: 14px;
  line-height: 143%;
  color: var(--gray-950);
}

.navLinkChecked {
  border: 1px solid var(--green-700);
  border-radius: 12px;
  background: var(--green-50);
  padding: 16px 20px;
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: #25204a;
}

.navLinkChecked span {
  color: var(--green-900);
  font-size: 14px;
  line-height: 143%;
  color: var(--gray-950);
}

.navLinkChecked img {
  color: var(--green-900);
}

.navLink:hover {
  //border: 1px solid var(--green-700);
  border-radius: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.navLink:hover span {
  color: var(--green-900);
}

.navLink:hover img {
  color: var(--green-900);
}

.moreButtonWrapper {
  position: relative;
}

.moreButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 16px 20px;
}

.dropdownMenu {
  position: absolute;
  min-width: 210px;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid var(--gray-200);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  z-index: 1000;
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  white-space: nowrap;
  font-weight: 600;
  font-size: 16px;
  color: #25204a;
  background: transparent;
  border: 1px solid transparent;
}

.dropdownItem img {
  margin-right: 8px;
}

.dropdownItem:hover {
  background: var(--gray-50);
}

.dropdownItem:hover span {
  color: var(--gray-950);
}
