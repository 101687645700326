.reportsPageTopSection {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--gray-200);
  border-radius: 12px;
  padding: 24px;
  position: relative;
  width: 100%;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 375px 1fr;
}

.reportsPageBottomSection {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--gray-200);
  border-radius: 12px;
  padding: 24px;
  position: relative;
  width: 100%;
  margin-top: 24px;
}

.filtersFormWrapper {
  padding-left: 64px;
}

.filtersFormContentWrapper {
  padding-right: 64px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.submitButtonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  min-height: 48px;
}

.clearFiltersButton {
  display: flex;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  color: var(--green-900);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  background-color: var(--white);
  border: none;
}

.clearFiltersButton:hover {
  background-color: var(--gray-50);
}

.applyFiltersButton {
  display: flex;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: var(--green-800);
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  border: none;
}

.twoColumnlayout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.formLabel {
  color: var(--Gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}

.filtersFormWrapper input,
.filtersFormWrapper select,
.filtersFormWrapper textarea {
  height: 48px;
  border-radius: 12px !important;
  color: var(--gray-500, #717186);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px;
}

.datePickerWrapper {
  height: 48px;
}

.datePickerWrapper > div > div {
  height: 48px;
  border: 1px solid var(--gray-400);
  border-radius: 12px;
  font-family: "nunito";
  font-size: 14px;
  background-color: var(--white);
}

.datePickerWrapper fieldset {
  border: none;
}

.filtersWrapper {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--gray-200);
  border-radius: 12px;
  padding: 24px;
  position: relative;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.filtersWrapper h3 {
  color: var(--black);
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
}

.inputGrid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.inputGrid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}

.inputGrid4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
}

.reportsPageWrapper input,
.reportsPageWrapper select,
.reportsPageWrapper textarea {
  height: 48px;
  border-radius: 12px !important;
  color: var(--gray-500);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px;
}

.expandButon {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 8px 16px 4px;
  background-color: transparent;
  color: var(--green-900);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 8px;
  gap: 8px;
}

.formSubTitle {
  margin-top: 16px;
  color: var(--gray-800);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 4px;
}

.additionalFieldsWrapper {
  margin-top: 16px;
}

.applyFiltersButton {
  display: flex;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  color: var(--white);
  background-color: var(--green-900);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  border: none;
  margin-left: auto;
}

.applyFiltersbuttonMarginTop {
  margin-top: 16px;
}
