.addLetterForm {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  gap: 12px;
}

.sendButton {
  background-color: var(--green-800);
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 180px;
  height: 48px;
}

.formCheckboxLabel {
  color: var(--gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  align-items: center;
  display: flex;
  gap: 8px;
}

.addLetterFormLabel {
  color: var(--Gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}

.inputGroupGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
  row-gap: 10px;
}

.inputGroupGrid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.addLetterForm input,
.addLetterForm select,
.addLetterForm textarea {
  height: 48px;
  border-radius: 12px !important;
  color: var(--gray-500, #717186);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px;
}

.openModalButton {
  background-color: white;
  color: var(--green-800);
  border: 1px solid var(--green-800);
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  height: 48px;
}

.bottomSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  border-top: 1px solid var(--gray-200);
  padding-top: 16px;
}

.bottomSection > div {
  display: flex;
  gap: 16px;
}

.bottomSection button {
  width: 248px;
}

.templateSection {
  margin-top: 12px;
}

.templateSection button {
  margin-top: 2px;
  width: 100%;
}

.addPregnancyDatePickerWrapper {
  height: 48px;
}

.addPregnancyDatePickerWrapper > div > div {
  height: 48px;
  border: 1px solid var(--gray-400);
  border-radius: 12px;
  font-family: "nunito";
  font-size: 14px;
  background-color: var(--white);
}

.addPregnancyDatePickerWrapper fieldset {
  border: none;
}

.switchWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--gray-950, #26252c);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 4px;
}

.switchWrapper span {
  margin-top: 6px;
}

.medicineSectionWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  gap: 12px;
  max-height: 50vh;
  overflow-y: scroll;
  border-bottom: 1px solid var(--gray-200);
  border-top: 1px solid var(--gray-200);
  padding-bottom: 16px;
  padding-top: 16px;
}

.medicineSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  gap: 12px;
  border-bottom: 1px solid var(--gray-200);
  margin-bottom: 24px;
  padding-bottom: 24px;
  position: relative;
  margin-right: 4px;
}

.medicineSection:last-child {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.removeButton {
  color: #dc3545;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: -12px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  padding: 0;
  border-radius: 8px;
  border: 1px solid var(--gray-200);
}

.addAnotherMedicine {
  width: 100%;
  padding: 10px;
  color: #328170;
  border: none;
  background: none;
  border-radius: 8px;
  cursor: pointer;
}

.bottomSectionWithoutBorder {
  border-top: none;
  margin-top: 0;
}

.medicineSectionWrapperWithoutBorder {
  border-top: none;
  padding-top: 0;
}

.downloadButton {
  position: absolute;
  bottom: 24px;
  left: 24px;
}
