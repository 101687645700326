.checkboxWrapper {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
}

.checkbox {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  cursor: pointer;
  z-index: 2;
}

.customCheckbox {
  width: 16px;
  height: 16px;
  background-color: transparent;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid var(--gray-950);
}

.checkbox:checked ~ .customCheckbox {
  background-color: var(--green-800);
}

.checkbox:disabled {
  cursor: not-allowed;
}

.checkbox:disabled:checked ~ .customCheckbox {
  background-color: var(--gray-300);
}

.checkbox:disabled ~ .customCheckbox {
  border-color: var(--gray-300);
  border-radius: 3px;
}

.customCheckbox::after {
  content: "";
  display: none;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.checkbox:checked ~ .customCheckbox::after {
  display: block;
}
