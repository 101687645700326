.wrapper {
  position: relative;
  gap: 24px;
  width: 100%;
  gap: 24px;
}
.firstRowWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 24px;
  margin-bottom: 24px;
}

.pageTitle {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 24px;
  margin-top: 20px;
}

.secondRowWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  gap: 24px;
  margin-bottom: 24px;
}

.thirdRowWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  gap: 24px;
}

.firstColumnWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  gap: 24px;
  flex: 1;
}
.titleWrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
}
.secondColumnWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 2;
  width: 100%;
}
.secondColumnSubWrapper {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  flex-grow: 1;
}
.thirdColumnWrapper {
  display: flex;
  gap: 24px;
  width: 100%;
  flex-direction: row;
  border: 1px solid black;
}

@media screen and (max-width: 1800px) {
  .thirdColumnWrapper {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1620px) {
  .secondColumnSubWrapper {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1440px) {
  .secondColumnWrapper {
    flex: 1;
  }
}
@media screen and (max-width: 1200px) {
  .wrapper {
    flex-direction: column;
  }
}

.toggleBtnGroup {
  border-radius: 12px !important;
  border: 1px solid var(--Gray-200, #d9d8df) !important;
  background: var(--White, #fdfdfd) !important;
  width: 100% !important;
  margin-bottom: 12px;
}

.toggleBtn {
  color: var(--Gray-950, #26252c) !important;
  font-family: Nunito !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-transform: none !important;
  width: calc(25% + 1px) !important;
  border: none !important;
  border-radius: 12px !important;
  height: 52px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: transparent !important;
  border: 0px solid var(--Green-700, #008056) !important;
  transition: none !important;
  animation: none !important;
}

.toggleBtn[aria-pressed="true"] {
  border-radius: 12px;
  border: 1px solid var(--Green-700, #008056) !important;
  background: var(--Green-50, #e7efeb) !important;
  color: var(--Green-700, #008056) !important;
}

.consultationWrapper {
  background: var(--white);
  position: relative;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--gray-200);
}

.topWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 0 24px 16px 24px;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 24px;
  width: calc(100% + 48px);
  margin-left: -24px;
  border-bottom: 1px solid var(--gray-200);
}
.title {
  font-weight: 600;
  font-size: 20px;
  color: #060030;
  width: 100%;
}
